html {
    overflow-x: hidden;
}

body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    --transitionTime: 0.3s;

    --Link: #5b97cd;
    --Black: #252d35;
    --sec50: #ced2d4;
    --sec100: #abb2b6;
    --sec200: #8a9399;
    --sec300: #535c62;
    --sec400: #363f45;
    --sec500: #2d363d;
    --sec600: #22292e;
    --sec700: #171b1f;
    --sec800: #0b0e0f;
    --sec900: #050506;

    --pri50: #ffeef0;
    --pri100: #fae3e5;
    --pri200: #f5c8cd;
    --pri300: #e99aa2;
    --pri400: #d66a75;
    --pri500: #a62531;
    --pri600: #880915;
    --pri700: #720611;
    --pri800: #5c0009;
    --pri900: #360006;

    --gr50: #fbfbfb;
    --gr100: #f5f7f8;
    --gr200: #f0f3f5;
    --gr300: #e8ecef;
    --gr400: #dfe5e9;
    --gr500: #d5dde1;
    --gr600: #c7d2d7;
    --gr700: #b3c0c6;
    --gr800: #9dacb2;
    --gr900: #859298;

    --green50: #def9dc;
    --green100: #c1edbe;
    --green200: #89d783;
    --green300: #66ca5d;
    --green400: #55b54d;
    --green500: #3da534;
    --green600: #319229;
    --green700: #23831b;
    --green800: #14740c;
    --green900: #0d6905;

    background: var(--gr50);
}

body::-webkit-scrollbar {
    width: 14px;
    height: 18px;
}

body::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
}

body::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

body::-webkit-scrollbar-corner {
    background-color: transparent;
}

p,
a,
span,
label,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.hidden {
    display: none;
}

input[type="password"],
input[type="tel"],
input[type="text"],
input[type="number"],
input[type="email"],
textarea {
    width: 100%;
    margin-right: 6px;
    background: #fff;
    border: 1px solid var(--gr400);
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    height: 44px;
    transition: var(--transitionTime);
    padding-left: 16px;
    font-family: "Inter", sans-serif;
    text-align: left;
    color: var(--Black);
}

input[type="password"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
textarea:focus {
    border: 1px solid var(--sec600);
}

.is-invalid {
    border: 2px solid var(--pri500) !important;
}

.is-invalidP {
    color: var(--pri500);
    font-size: 14px;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: textfield;
}

textarea {
    height: 140px;
    resize: vertical;
    padding-top: 10px;
    padding-bottom: 10px;
}

a:hover {
    text-decoration: none;
}

a.PAexitBtn {
    margin-bottom: 15px;
}

hr {
    margin: 0;
}

h1 {
    font-size: 36px;
    color: var(--Black);
}

h2 {
    font-size: 30px;
    color: var(--Black);
}

h3 {
    font-size: 24px;
    color: var(--Black);
}

h4 {
    font-size: 20px;
    color: var(--Black);
}

h5 {
    font-size: 16px;
    color: var(--Black);
}

h6 {
    font-size: 12px;
    color: var(--Black);
}

li {
    list-style-type: none;
}

.templateBox {
    border: 1px solid var(--gr300);
    border-bottom: 4px solid var(--gr300);
    padding: 50px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

select {
    display: block;
    width: 100%;
    height: 44px;
    background-color: #fff;
    border: 1px solid var(--gr400);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px 16px;
    color: var(--Black);
    outline: none;
    cursor: pointer;
    appearance: none;
    background: url(~/img/selectArr.svg) no-repeat right, #fff;
    background-position-x: calc(100% - 8px);
}

select:focus {
    border: 1px solid var(--sec600);
}

select option {
    cursor: pointer;
}

.main__wrap {
    position: relative;
    margin: 0 18%;
}

img.homeBG_left {
    position: absolute;
    top: 8%;
    left: 0;
    z-index: -1;
}

img.homeBG_right {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 26%;
}

.passMatch {
    position: relative;
    border: 1px solid var(--pri500);
}

.passMatch::after {
    content: "Пароли не совпадают";
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    color: var(--pri500);
    font-weight: 700;
}

.email_exist {
    position: relative;
    border: 1px solid var(--pri500);
}

.email_exist::before {
    content: "Такой Email уже зарегистрирован";
    position: absolute;
    top: 0px;
    right: 0;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    color: var(--pri500);
    font-weight: 700;
}

/*HEADER*/
header {
    width: 100%;
    height: 80px;
    min-height: 80px;
    background: var(--gr100);
}

header select {
    background: var(--pri500);
    background-position-x: calc(100% - 22px);
    padding: 12px 41px 12px 22px;
    box-sizing: border-box;
    font-size: 14px;
    color: var(--gr50);
    border-radius: 4px;
}

.headerSelect__wrap {
    position: relative;
}

.headerSelect__wrap::before {
    position: absolute;
    top: 11px;
    right: 19.5px;
    width: 9px;
    height: 5px;
    content: url(~/img/selectArrWhite.svg);
}

.header__wrap {
    height: 100%;
    display: flex;
}

.header__logo {
    height: 90px;
    flex: 1;
}

.header__logo img {
    height: 100%;
}

.header__box {
    display: flex;
    align-items: center;
    height: 100%;
}

.header__geo {
    display: none;
    margin-left: 3%;
}

.header__nav nav {
    display: flex;
    align-items: center;
    height: 100%;
}

.header__nav nav a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Black);
    height: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    transition: var(--transitionTime);
}

.header__nav nav a:hover {
    background: var(--gr300);
}

// .header__nav nav a:not(:last-child) {
//     margin-right: 30px;
// }
.header__geo img {
    margin-right: 10px;
}

.header__geo span {
    text-decoration: underline;
}

.header__account {
    margin-left: 3%;
    display: none;
}

.header__account select option {
    background: #fff;
    color: var(--Black);
}

.header__account {
    display: none;
    margin-left: 3%;
    cursor: pointer;
}

.header__account_active {
    display: flex;
    align-items: center;
    height: 100%;
}

.header__account_active a {
    margin-left: 10px;
}

.header__account__user {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 40px;
}

.header__account__user p {
    margin-right: 12px;
}

.header__account_active span {
    margin-right: 12px;
}

.header__account_active img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    object-fit: cover;
}

img.accountArrDown {
    width: 9px;
    height: 5px;
    margin-left: 10px;
}

.header__account__user__drop {
    position: absolute;
    top: 60px;
    right: 0;
    height: 0;
    overflow: hidden;
    transition: var(--transitionTime);
}

.header__account__user__drop__open {
    height: auto;
    transition: var(--transitionTime);
    z-index: 9;
}

.header__account__user__drop ul {
    background: #fff;
    box-shadow: 0px 30px 50px rgba(54, 63, 69, 0.22);
    border-radius: 5px;
    width: max-content;
    padding: 6px 0px;
    box-sizing: border-box;
}

.header__account__user__drop ul li {
    box-sizing: border-box;
    transition: var(--transitionTime);
    width: 100%;
    border-radius: 4px;
}

.header__account__user__drop ul li:nth-child(5) {
    border-top: 1px solid var(--sec50);
    margin-top: 6px;
    padding-top: 6px;
}

.header__account__user__drop ul li a {
    display: block;
    color: var(--Black);
    margin-right: 0;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
}

// .header__account__user__drop ul li:nth-child(4) a {
//     color: var(--pri400);
// }
.header__account__user__drop ul li:hover {
    background: var(--gr300);
}

// .header__account__user__drop ul li:hover a {
//     color: var(--gr50);
// }
/***HEADER***/

/*FOOTER*/
footer {
    width: 100%;
    background: var(--gr100);
    margin: auto 0 0 0;
}

footer hr {
    width: 100%;
    height: 1px;
    background: var(--gr500);
    border: none;
}

.footer__wrap {
    display: flex;
    flex-direction: column;
}

.footer__top {
    display: flex;
    width: 90%;
    justify-content: space-between;
    padding: 50px 0 40px 0;
}

.footer__top__box__inline {
    display: flex;
}

.footer__top__box__inline a {
    margin-right: 14px;
}

.footer__top__box .footer__top__box__inline a:last-child {
    margin-bottom: 12px;
}

.footer__top__box {
    display: flex;
    flex-direction: column;
}

.footer__top__box p {
    color: var(--Black);
    margin-bottom: 16px;
    font-weight: 700;
}

.footer__top__box a {
    position: relative;
    color: var(--sec200);
    margin-bottom: 16px;
    margin-bottom: 12px;
    width: fit-content;
    font-size: 14px;
}

.footer__top__box a:last-child {
    margin-bottom: 0;
}

.footer__top__box a::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 1px;
    background: var(--sec200);
    transition: var(--transitionTime);
}

.footer__top__box a:hover:after {
    width: 100%;
}

.footer__bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 82px;
}

.footer__bottom__box a,
.footer__bottom__box p {
    color: var(--sec200);
}

.footer__bottom__boxCompany {
    display: flex;
    flex-direction: column;
}

.footer__bottom__boxCompany p {
    margin-bottom: 6px;
}

.footer__bottom__boxCompany a {
    color: var(--Black);
    text-decoration: underline;
}

.footer__bottom__boxPolicy a {
    text-decoration: underline;
}

/***FOOTER***/

/*RANGE*/
.range {
    display: block;
    position: relative;
    height: 5px;
    border-radius: 4px;
    background-color: #535c62;
    width: 100%;
}

.range__button_1,
.range__button_2 {
    position: absolute;
    top: 50%;
    width: 34px;
    height: 34px;
    cursor: move;
    transform: translateY(-50%);
    border: none;
    border-radius: 50%;
    background-color: var(--pri500);
}

.range__button_1::after {
    position: absolute;
    top: 10px;
    left: 11px;
    display: block;
    content: url(~/img/rangeIcon.svg);
    width: 10px;
    height: 10px;
    margin: 0;
}

.range__button_2::after {
    position: absolute;
    top: 10px;
    left: 11px;
    display: block;
    content: url(~/img/rangeIcon.svg);
    width: 10px;
    height: 10px;
    margin: 0;
}

.range__between {
    position: absolute;
    top: -3px;
    height: 5px;
    width: 0px;
    margin-top: 3px;
    border: none;
    background-color: var(--pri500);
}

.range_inpt1,
.range_inpt2 {
    margin-top: 20px;
    background: none !important;
    border: none !important;
}

/***RANGE***/

/*CHECKMARK*/
.container {
    display: flex;
    position: relative;
    padding-left: 28px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    cursor: pointer;
}

.container p {
    color: var(--Black);
    font-size: 16px;
    font-weight: 400;
}

.container span {
    font-size: 16px;
    font-weight: 400;
    margin-left: 4px;
    color: var(--sec100);
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    border: 2px solid #d5dde1;
    box-sizing: border-box;
    border-radius: 2px;
    box-sizing: border-box;
    transition: var(--transitionTime);
}

.container:hover input ~ .checkmark {
    border: 2px solid var(--pri500);
}

.container input:checked ~ .checkmark {
    background-color: var(--pri500);
    border: 2px solid var(--pri500);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmark:after {
    display: block;
}

.container .checkmark:after {
    content: url(~/img/checkMark.svg);
    left: 3px;
    top: -5px;
    width: 5px;
    height: 10px;
}

.container span {
    margin-bottom: 0;
}

/***CHECKMARK***/

/*MAINSEARCH*/
section.mainSearch {
    display: flex;
    flex-direction: column;
}

.mainSearch__toogle {
    display: flex;
    width: 100%;
    height: 73px;
}

.mainSearch__toogle__Btn {
    display: flex;
    width: 50%;
    height: 100%;
    padding: 26px 36px;
    box-sizing: border-box;
    background: var(--sec50);
    cursor: pointer;
    transition: var(--transitionTime);
    font-weight: 500;
}

.mainSearch__toogle__Btn__active {
    background: var(--sec600);
    transition: var(--transitionTime);
}

.mainSearch__toogle__Btn__active h5 {
    color: var(--gr50) !important;
}

#mainToggle1 {
    border-radius: 8px 0px 0px 0px;
}

#mainToggle2 {
    border-radius: 0px 8px 0px 0px;
}

.mainSearch__toogle__Btn h5 {
    color: var(--Black);
}

.mainSearch__search {
    padding: 30px 13.6%;
    box-sizing: border-box;
    background: var(--sec600);
    border-radius: 0px 0px 5px 5px;
}

.mainSearch__search form {
    display: flex;
    flex-direction: column;
}

.mainSearch__search form p {
    color: var(--sec200);
}

.mainSearch__search form p.blackSearchText {
    color: var(--Black);
    cursor: default;
}

.formSearch__input__wrap {
    position: relative;
    width: 100%;
    margin-right: 6px;
}

// .formSearch__input__wrap::before {
//     content: url(~/img/searchIconInput.svg);
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 12.5px;
//     height: 12.5px;
// }
.mainSearch_result {
    display: none;
    position: absolute;
    top: 42px;
    left: 0;
    width: 98.5%;
    background: #ffffff;
    box-shadow: 0px 30px 50px rgba(54, 63, 69, 0.22);
    border-radius: 5px;
    padding: 6px 0;
}

.mainSearch_result__open {
    display: block;
}

.mainSearch_result p {
    display: block;
    background: #fff;
    min-height: 40px;
    transition: var(--transitionTime);
    padding: 10px 20px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    color: var(--sec200);
}

.mainSearch_result p.blackSearchText {
    color: var(--Black);
    cursor: default;
}

.mainSearch_result p:hover {
    color: var(--Black);
}

p.searchVoid {
    color: var(--sec100);
}

.mainSearch_result p.searchVoid:hover {
    color: var(--sec100);
    cursor: default;
}

.formSearch {
    display: flex;
    align-items: center;
}

.btns {
    display: block;
    padding: 13px 22px;
    box-sizing: border-box;
    border-radius: 4px;
    color: var(--textBlack);
    font-size: 14px;
    background: var(--gr300);
    transition: var(--transitionTime);
    border: none;
    cursor: pointer;
    width: fit-content;
    white-space: nowrap;
    text-align: center;
}

.btns:hover {
    background: var(--gr500);
}

.btnsFull {
    width: 100%;
}

.btnsDisabled {
    display: block;
    padding: 12px 22px;
    box-sizing: border-box;
    border-radius: 3px;
    color: var(--textBlack);
    font-size: 14px;
    background: var(--gr300);
    transition: var(--transitionTime);
    border: none;
    cursor: pointer;
    width: fit-content;
    white-space: nowrap;
    text-align: center;
    margin-right: 10px !important;
    cursor: no-drop;
}

.colorBtn {
    color: var(--gr50) !important;
    background: var(--pri500) !important;
    transition: var(--transitionTime);
}

.colorBtn:hover {
    box-shadow: 0px 8px 20px rgba(92, 0, 0, 0.28);
    background: #9d0d1b;
}

.unColorBtn {
    background: var(--gr300);
    color: var(--sec300);
}

input.search {
    width: 100%;
    margin-right: 6px;
    background: #fff;
    border: 1px solid var(--gr400);
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    height: 42px;
    transition: var(--transitionTime);
    color: var(--Black);
}

.formSearch {
    margin-bottom: 8px;
}

.formSearch .dropdown {
    margin-right: 6px;
}

.filterBox__mobil {
    display: none;
}

.form__filters {
    display: flex;
    cursor: pointer;
    width: fit-content;
}

.form__filters span {
    margin-right: 5px;
    color: var(--sec200);
}

.form__filtersBox {
    display: flex;
    flex-direction: column;
    background: var(--sec400);
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.07);
    border-radius: 5px;
    padding: 0;
    box-sizing: border-box;
    margin: 0 0 0 0;
    height: 0;
    overflow-y: hidden;
    transition: var(--transitionTime);
    width: 100%;
}

.form__filters__open {
    padding: 30px;
    margin: 20px 0 30px 0;
    transition: var(--transitionTime);
    height: auto;
}

.form__filtersBox__title {
    display: flex;
    flex-direction: column;
}

.form__filtersBox__title h5 {
    color: var(--gr50);
}

.form__filtersBox__title__result {
    display: flex;
    align-items: center;
    width: 48%;
}

.form__filtersBox__title__result label {
    margin-right: 10px;
    color: var(--sec100);
    font-size: 14px;
}

.form__filtersBox__title__result input {
    background: var(--sec300);
    color: var(--gr50);
    border: none;
}

.form__filtersBox__title__result input:focus {
    border: 1px solid var(--sec600);
}

.form__filtersBox__title__wrap {
    display: flex;
    justify-content: space-between;
    margin: 14px 0 30px 0;
}

.form__filtersBox__title__result input::-webkit-outer-spin-button,
.form__filtersBox__title__result input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form__filtersBox__check {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 18px;
}

.form__filtersBox__checkBox:first-child {
    width: 100%;
    margin-bottom: 30px;
}

.form__filtersBox__checkBox:first-child ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.form__filtersBox__checkBox:first-child ul li {
    width: 50%;
}

.form__filtersBox__checkBox {
    width: 50%;
}

.form__filtersBox__checkBox ul {
    width: 100%;
}

.form__filtersBox__checkBox ul li {
    width: 100%;
}

.form__filtersBox__checkBox span {
    display: block;
    font-weight: 700;
    margin-bottom: 12px;
    color: #fff;
}

.form__filtersBox__checkBox .container span {
    color: var(--sec100);
    font-weight: 400;
    margin: 0 20px 0 auto;
}

.form__filtersBox__checkBox .checkmark {
    border: 2px solid var(--sec300);
}

.telInput__wrap {
    display: flex;
    margin-bottom: 10px;
}

.telInput__wrap select {
    margin-right: 10px;
    width: 30%;
}
.register__form__box .telInput__wrap select {
    width: 40%;
}

.telInput__wrap input {
    margin-right: 0;
}

.form__filtersBox__checkBox ul {
    padding: 0;
}

.form__filtersBox__checkBox ul::-webkit-scrollbar {
    width: 14px;
    height: 18px;
}

.form__filtersBox__checkBox ul::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
}

.form__filtersBox__checkBox ul::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.form__filtersBox__checkBox ul::-webkit-scrollbar-corner {
    background-color: transparent;
}

.mainSearch__postBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    background: var(--pri500);
    cursor: pointer;
    transition: var(--transitionTime);
    border-radius: 5px;
}

.mainSearch__postBtn:hover {
    background: var(--pri600);
}

.mainSearch__postBtn a {
    color: var(--gr50);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form__filters__open .btns {
    margin: 0 0 0 auto;
}

/***MAINSEARCH***/

/*LOGIN*/
section.login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: url(~/img/loginBG.jpg) no-repeat center;
}

section.register__applicant {
    background: url(~/img/RegisterManBG.jpg) no-repeat center;
}

section.register__employer {
    background: url(~/img/registerCompBG.jpg) no-repeat center;
}

section.register__employer .login__form form input:not([type="submit"]) {
    margin-bottom: 0;
}

.login__wrap {
    display: flex;
    flex-direction: column;
    padding: 50px 60px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    transition: var(--transitionTime);
}

#numberSelect {
    padding: 0;
    text-align: center;
    text-align-last: center;
    display: none;
}

.userResumeNoActive {
    color: var(--pri500);
}

.PArateBox__btn__wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

span.attentionBlock {
    display: flex;
    width: fit-content;
    align-items: center;
    background: var(--pri50);
    padding: 8px 14px 8px 10px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 4px;
    z-index: 1;
}

span.attentionBlock img {
    margin-right: 12px;
    width: 20px;
    height: 20px;
}

span.attentionBlock p {
    color: var(--pri400);
    font-weight: 500;
    font-size: 14px;
    line-height: 19.6px;
}

span.attentionPrompt {
    position: absolute;
    top: 42px;
    left: 0;
    display: none;
    padding: 8px 12px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid var(--gr100);
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 5px;
    z-index: 15;
}

.login__close {
    transform: translateY(-150%);
}

.back_btn {
    margin-bottom: 30px;
}

.back_btn a {
    display: flex;
}

.back_btn a img {
    margin-right: 10px;
}

.back_btn a p {
    color: var(--sec200);
    font-weight: 500;
}

.login__title {
    margin-bottom: 30px;
}

.login__title h1 {
    font-weight: 700;
    color: var(--Black);
}

.login__form {
    margin-bottom: 30px;
}

.login__form form {
    display: flex;
    flex-direction: column;
}

.login__form form label {
    display: block;
    padding: 12px 0 12px 0;
    font-weight: 700;
    color: var(--Black);
    font-size: 14px;
}

.login__form form input:not([type="submit"]) {
    display: block;
    height: 42px;
    width: 100%;
    background: #fff;
    border: 1px solid var(--gr400);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 12px 16px;
    color: var(--Black);
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 19.6px;
}

.login__form_btn {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.login__form_btn input {
    margin-right: 20px;
}

.login__form_btn span {
    position: relative;
    color: var(--sec200);
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    transition: var(--transitionTime);
}

.login__form_btn span::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 1px;
    background: var(--Black);
    transition: var(--transitionTime);
}

.login__form_btn span:hover {
    color: var(--Black);
}

.login__form_btn span:hover:after {
    width: 100%;
}

.login__sn {
    display: flex;
    flex-direction: column;
    display: none;
}

.login__sn__text {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 14px;
}

.login__sn__text span {
    display: block;
    position: relative;
    width: fit-content;
    color: var(--sec200);
    font-size: 14px;
    z-index: 2;
    padding: 0 20px;
    box-sizing: border-box;
    background: var(--gr50);
}

.login__sn__text hr {
    display: block;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--gr300);
}

.login__sn__href {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.login__sn__href a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32%;
    height: 40px;
    background: var(--snBG);
    border-radius: 5px;
}

.login__sn__href a img {
    display: block;
    height: 55%;
}

.login__register {
    display: flex;
}

.login__register p {
    color: var(--sec200);
    margin-right: 5px;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
}

.login__register a {
    color: var(--Link);
    font-size: 14px;
    font-weight: 500;
}

.login__register hr {
    display: block;
    border: none;
    border-left: 1px solid var(--gr400);
    height: 20px;
    margin: 0 10px;
}

/***LOGIN***/

/*REGISTER*/
.register__wrap {
    width: 50%;
    min-width: 800px;
}

.register__form form {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.register__form form label {
    padding: 0;
    margin-bottom: 10px;
}

.register__form__box {
    width: 48%;
    margin-bottom: 20px;
}

.register__form__box:nth-child(8) {
    width: 100%;
}

.register__full {
    position: relative;
    width: 100%;
}

.login__form_btn p {
    color: var(--sec200);
    margin-right: 5px;
    font-weight: 500;
}

.login__form_btn a {
    color: var(--Link);
    font-weight: 500;
}

.register__full .dropdown {
    width: 100%;
}

.register__form__box ul {
    width: 100%;
}

.register__form__box ul li {
    color: var(--sec200);
    display: block;
    padding: 12px 16px;
    box-sizing: border-box;
    cursor: pointer;
}

.register__form__box .dropdown-toggle::after {
    margin-left: 26.25em;
}

.register__rule {
    margin-top: 16px;
    display: flex;
    display: none;
}

.register__rule p {
    line-height: 19.6px;
    font-size: 14px;
    color: var(--sec200);
}

.register__rule a {
    line-height: 19.6px;
    font-size: 14px;
    color: var(--Link);
    text-decoration: underline;
}

.privacy__wrap {
    display: flex;
}
.privacy__wrap .container {
    padding-left: 28px;
}
.privacy__wrap .container p {
    font-size: 14px;
    padding-top: 1.5px;
    box-sizing: border-box;
}
.privacy__wrap .container a {
    color: inherit;
    text-decoration: underline;
}
.privacy__wrap .container input[type="checkbox"] {
    height: 1px;
    margin: 0;
}
/***REGISTER***/

/*SEARCHWORK*/
.searchWork {
    position: relative;
    display: flex;
    padding: 36px 0 40px;
    padding-left: 18%;
    background: #eff2f5;
    margin-bottom: 40px;
}

img.targetSearchBGLeft {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

img.targetSearchBGRight {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.searchWork__wrap {
    display: flex;
    flex-direction: column;
    width: 700px;
    z-index: 2;
}

.searchWork__title {
    margin-bottom: 8px;
}

.searchWork__title p {
    color: var(--Black);
    font-weight: 500;
}

.searchWork__toggle {
    display: flex;
}

.searchWork__toggle a.btns {
    background: var(--gr400);
    margin-right: 4px;
    margin-bottom: 6px;
}

.searchWork__toggle a.searchWork__toggle_active {
    background: var(--pri500);
    color: var(--gr50);
    margin-bottom: 6px;
}

.searchWork__input input {
    padding-left: 17px;
    color: var(--Black);
}

.formSearch select {
    display: block;
    height: 42px;
    width: 97px;
    margin-right: 6px;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid var(--gr400);
    box-sizing: border-box;
    border-radius: 3px;
    text-align-last: center;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 5px;
    font-weight: 50;
    font-size: 14px;
    color: var(--Black);
    font-weight: 500;
}

/***SERACHWORK***/

/*CATEGORYBOX*/
.catBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 24px;
    background: #fff;
    border: 1px solid var(--gr400);
    box-sizing: border-box;
    border-radius: 8px;
    width: 32.09%;
    height: 160px;
    margin-right: 1.81%;
    margin-bottom: 20px;
}

.catBox:nth-child(3n) {
    margin-right: 0;
}

// .catBox:hover {
//     background: #ffffff;
//     border: 1px solid #f5f7f8;
//     box-sizing: border-box;
//     box-shadow: 0px 30px 50px rgba(54, 63, 69, 0.22);
//     border-radius: 8px;
// }
.catBox__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 31px;
}

.catBox__title a {
    font-weight: 700;
    color: var(--Black);
}

.filterBox__btns {
    position: inherit;
    z-index: 15;
    width: 100%;
}

.filterBox__btns__open {
    position: fixed;
    top: 0;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    transform: translateX(-23px);
    padding: 20px;
    box-sizing: border-box;
}

.filterBox__btns__open a.resetSettingsFilter {
    margin-bottom: 0;
}

.catBox__Btn span {
    color: var(--pri500);
    font-weight: 600;
    font-size: 14px;
}

.catBox__text {
    width: 100%;
    margin-bottom: 31px;
    height: 60px;
    max-height: 60px;
    min-height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: none;
}

.catBox__text p {
    color: var(--sec300);
}

.catBox__Btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.catBox__price {
    display: flex;
    flex-direction: column;
}

.catBox__price h5 {
    font-weight: 700;
    margin-bottom: 5px;
    color: var(--Black);
}

.catBox__price p {
    color: var(--sec200);
    font-size: 14px;
}

/***CATEGORYBOX***/

/*RESUMEBOX*/
.resumeCompressBox {
    display: flex;
    flex-direction: column;
    background: #fff;
    border: 1px solid #f5f7f8;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.07);
    border-radius: 8px;
    padding: 24px 30px;
    transition: var(--transitionTime);
    margin-bottom: 20px;
}

.resumeCompressBox .resumeCompressBox__title__info__box img {
    display: none;
}

.resumeCompressBox__wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.resumeCompressBox__img {
    width: 20.2%;
    border-radius: 5px;
}

.resumeCompressBox__img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.resumeCompressBox__info {
    display: flex;
    flex-direction: column;
    width: 74.3%;
}

.resumeCompressBox__title__info__text {
    display: flex;
    align-items: flex-start;
}
.resumeCompressBox__title__info__text a {
    margin-right: 10px;
}

.resumeCompressBox__title {
    display: flex;
    justify-content: space-between;
}

.resumeCompressBox__title__info {
    display: flex;
    flex-direction: column;
    width: 98%;
}

.resumeCompressBox__title__info h4 {
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--Black);
}

.resumeCompressBox__title__info p,
.resumeCompressBox__title__info a {
    font-size: 16px;
    font-weight: 500;
    color: var(--sec300);
    margin-bottom: 6px;
}

.resumeCompressBox__title__info a {
    font-size: 20px;
    font-weight: 700;
    color: var(--Black);
    width: fit-content;
    max-width: 75%;
}

.resumeCompressBox__title__info span.colorBtn {
    border-radius: 40px;
    padding: 6px 12px;
    box-sizing: border-box;
    margin-top: 36px;
}

.resumeCompressBox__title__info span.btns:hover {
    background: var(--pri500);
    box-shadow: none;
    cursor: default;
}

// .resumeCompressBox__title__favoriteIcon {
//     display: none;
// }

.videoBlock {
    display: none;
    width: 100%;
}

.videoBlock__show {
    display: block;
    margin-top: 17px;
    height: 360px;
    max-width: 635px;
}

.resumeCompressBox__title__favoriteIcon svg {
    transition: var(--transitionTime);
    stroke: var(--sec200);
}

.resumeCompressBox__title__favoriteIcon svg:hover {
    stroke: var(--yellow);
}

.resumeCompressBox__title__favoriteIcon svg.active {
    stroke: var(--yellow);
    fill: var(--yellow);
}

.resumeCompressBox__text {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.resumeCompressBox__text__info {
    display: flex;
    margin-bottom: 8px;
}

.resumeCompressBox__text__info p {
    display: block;
    margin-right: 14px;
}

p.resimeName {
    font-size: 16px;
    font-weight: 700;
    color: var(--Black);
    max-width: 70%;
}

p.resimeYear {
    font-size: 16px;
    font-weight: 500;
    color: var(--sec200);
}

p.resimeCity {
    font-size: 16px;
    font-weight: 500;
    color: var(--sec200);
}

.resumeCompressBox__text__other {
    display: flex;
    flex-wrap: wrap;
}

.resumeCompressBox__text__other p {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: var(--sec200);
    margin-right: 20px;
    margin-bottom: 6px;
}

.resumeCompressBox__text__other p::after {
    content: url(~/img/dots.svg);
    position: absolute;
    top: -3px;
    right: -12px;
    width: 4px;
    height: 4px;
}

.resumeCompressBox__text__other p:last-child::after {
    display: none;
}

.resumeCompressBox__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
}

.resumeCompressBox__tags span {
    display: block;
    background: var(--gr200);
    border-radius: 60px;
    padding: 6px 12px;
    box-sizing: border-box;
    font-size: 12px;
    margin-right: 8px;
    margin-bottom: 8px;
    color: var(--sec300);
}

.resumeCompressBox__tags span:hover {
    box-shadow: none;
    cursor: default;
}

.resumeCompressBox__tags span:last-child {
    margin-right: 0;
}

.resumeCompressBox__btn {
    display: flex;
    align-items: flex-end;
    z-index: 1;
}

.resumeCompressBox__btn p {
    color: var(--sec200);
    font-size: 14px;
    height: fit-content;
}

.resumeCompressBox__btn p:last-child {
    margin: 0 0 0 auto;
}

.resumeCompressBox__btn p.btns {
    margin: 0 10px 0 0;
}

.resumeCompressBox__btn p.btns:hover {
    cursor: not-allowed;
    background: var(--gr300);
}

.resumeCompressBox__btn span {
    margin-right: 10px;
}
.similarTitle {
    margin-top: 50px;
}
.cke_notification_warning {
    display: none;
}
/***RESUMEBOX***/

/*RESUMEBOX*/
.vacancyCompressBox__info {
    width: 100%;
}

.vacancyCompressBox__info .resumeCompressBox__title {
    margin-bottom: 20px;
}

.vacancyCompressBox__text {
    margin-bottom: 24px;
}

.vacancyCompressBox__text p {
    color: var(--sec300);
    font-size: 14px;
    line-height: 1.4em;
    overflow-wrap: anywhare;
}

.vacancyCompressBox__company {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.vacancyCompressBox__company a {
    width: fit-content;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
    color: var(--Black);
}

.vacancyCompressBox__company object {
    display: flex;
    flex-direction: column;
}

.vacancyCompressBox__company p {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
    color: var(--Black);
    width: fit-content;
}

.vacancyCompressBox__company span {
    color: var(--sec300);
    font-size: 14px;
    font-weight: 400;
}

.vacancyCompressBox__info .resumeCompressBox__title__info p,
.vacancyCompressBox__info .resumeCompressBox__title__info span {
    color: var(--pri500);
}

.resumeCompressBox__title__info__box {
    display: flex;
}

.resumeCompressBox__title__favoriteIcon svg {
    cursor: pointer;
    stroke: var(--gr600);
    transition: var(--transitionTime);
}

.resumeCompressBox__title__favoriteIcon svg path:nth-child(1) {
    fill: var(--gr600);
}

.resumeCompressBox__title__favoriteIcon svg:hover {
    stroke: #ffbe40;
}

.resumeCompressBox__title__favoriteIcon svg:hover path:nth-child(1) {
    stroke: #ffbe40;
    fill: #ffbe40;
}

.resumeCompressBox__title__favoriteIcon svg:hover path:nth-child(2) {
    stroke: #ffbe40;
}

.resumeCompressBox__title__favoriteIcon svg.active path:nth-child(1) {
    fill: #ffbe40;
    stroke: #ffbe40;
}

.resumeCompressBox__title__favoriteIcon svg.active path:nth-child(2) {
    stroke: #ffbe40;
}

.resumeCompressBox__title__favoriteIcon svg.active {
    fill: #ffbe40;
    transform: scale(1.2);
}

.resumeCompressBox__title__info__box a {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 14px;
    color: var(--Black);
    font-size: 20px;
    margin-bottom: 10px;
}

.resumeCompressBox__title__info__box a svg {
    margin-left: 14px;
}

.resumeCompressBox__title__info__box a::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: var(--Black);
    transition: var(--transitionTime);
}

.resumeCompressBox__title__info__box a:hover:after {
    width: 100%;
}

.vacancyCompressBox__quickly .resumeCompressBox__title__info__box img {
    display: block;
}

.vacancyCompressBox__quickly {
    border: 2px solid var(--sec200);
}

/***RESUMEBOX***/

/*FILTERS*/
.filders {
    display: flex;
    width: 100%;
    max-width: 280px;
    padding: 20px 22px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #f5f7f8;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.07);
    border-radius: 8px;
    margin-bottom: 30px;
}

.filders__wrap {
    width: 100%;
    height: 100%;
    position: relative;
}

.filders__wrap form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: var(--transitionTime);
}
.filterData__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: var(--transitionTime);
}
.forFilters__wrap {
    display: none;
}

.filders__wrap form input[type="submit"] {
    display: block;
    top: 0;
    max-width: 100%;
}

.showFiltersBtn {
    position: absolute;
    bottom: -50px;
    width: 100%;
}

.container span.quntity {
    font-size: 14px;
    color: var(--sec200);
    margin: 0 0 0 4px;
    font-weight: 400;
}

.quntity {
    display: none !important;
}

.quntityHide {
    display: none !important;
}

.filterBox {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.filterBox:last-child {
    margin-bottom: 0;
}

.filterBox select {
    margin-bottom: 10px;
}

a.resetSettingsFilter {
    font-size: 14px;
    font-weight: 500;
    color: var(--sec200);
    margin: 8px 0 30px 0;
    cursor: pointer;
}

.filterBox h5 {
    font-weight: 700;
    margin-bottom: 12px;
}

.filterBox__price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.filterBox__price input {
    background: #ffffff;
    border: 1px solid #dfe5e9;
    box-sizing: border-box;
    border-radius: 3px;
    width: 82%;
}

.filders .container p {
    font-size: 14px;
    width: 90%;
}

span.filtersMoreBTN {
    color: var(--Link);
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    margin-top: 12px;
}

.filterBox__more {
    transition: var(--transitionTime);
}

.filterBox__more ul.folded {
    height: 63px;
    overflow-y: hidden;
    transition: var(--transitionTime);
}

.filterBox__more ul.expand {
    height: fit-content !important;
    transition: var(--transitionTime);
}

.filterBox__more ul.folded li {
    margin-bottom: 15px;
}

.filterBox__more .container {
    margin-bottom: 0;
}

.priceContainer {
    margin-bottom: 31px;
}

/***FILTERS***/

/*AVATAR*/
.PAavatar {
    display: flex;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    // transform: translateY(110px);
}

.PAavatar__wrap {
    display: flex;
    position: relative;
}

.PAavatar__img {
    position: absolute;
    top: -3px;
    left: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.PAavatar__img__wrap {
    position: relative;
    height: 100%;
}

.PAavatar__img__wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

#changeAvatar {
    display: none;
}

.PAavatar__img__wrap label {
    display: block;
    position: absolute;
    bottom: 0;
    right: 20%;
    width: 30px;
    height: 30px;
    transition: var(--transitionTime);
    cursor: pointer;
}

.PAavatar__img__wrap label:hover {
    transform: rotate(180deg);
}

.PAavatar__substrate {
    display: flex;
    height: 247px;
    width: 200px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: var(--green);
    border-radius: 11px;
    padding: 19px;
    box-sizing: border-box;
    border-top-left-radius: 45%;
    border-top-right-radius: 45%;
    transition: var(--transitionTime);
    overflow: hidden;
}

.PAavatar__substrate__open {
    height: 310px;
}

.PAavatar__substrate__employer {
    background: transparent;
    height: 210px;
}

.PAavatar__substrate__box {
    display: flex;
}

.PAavatar__substrate__box img {
    margin-right: 12px;
}

.PAavatar__substrate__box p {
    color: var(--gr50);
    font-size: 14px;
    font-weight: 500;
}

.PAUserID {
    margin-top: 15px;
}

.PAUserID__wrap {
    width: 200px;
    height: 78px;
    background: #ffffff;
    border: 1px solid var(--gr400);
    padding: 16px;
    box-sizing: border-box;
}

.PAUserID__box {
    display: flex;
    justify-content: space-between;
}

.PAUserID__box__text {
    display: flex;
    flex-direction: column;
    width: 59.76%;
}

.PAUserID__box__text p {
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: var(--Black);
}

.PAUserID__box__text span {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    color: var(--sec200);
}

.PAUserID__box__copyBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28.05%;
    background: var(--gr100);
    border-radius: 10px;
    cursor: pointer;
    cursor: copy;
}

.PAUserID__box__copyBtn__wrap {
    width: 34.78%;
}

.PAUserID__box__copyBtn__wrap img {
    width: 100%;
    height: 100%;
}

.PAUserID__box__copyBtn__wrap textarea {
    display: none;
}

p.copyConfirm {
    opacity: 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--sec200);
    visibility: hidden;
    margin: 15px 0;
    transition: var(--transitionTime);
}

/***AVATAR***/

/*PAPERSONALDATA*/
.brief__form__box input[type="radio"] {
    border: none;
    cursor: pointer;
}

.radio {
    position: relative;
    margin-bottom: 1rem;
}

.radio_input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
}

.radio_label {
    padding-left: 25px;
    font-size: 1rem;
    color: var(--Black);
    cursor: pointer;
}

.radio_label:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid var(--sec50);
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: border 0.1s linear;
}

.radio_label:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: var(--pri500);
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    top: 3px;
    left: 4px;
    z-index: 2;
    transition: opacity 0.1s linear;
}

.radio_input:hover ~ .radio_label:before {
    border: 1px solid var(--pri500);
}

.radio_input:checked + .radio_label:before {
    border-color: var(--sec50);
}

.radio_input:checked + .radio_label:after {
    opacity: 1;
}

.personalData {
    display: flex;
    flex-direction: column;
}

.personalData h4 {
    margin-bottom: 30px;
}

.personalData form {
    position: relative;
    display: flex;
    flex-direction: column;
}

.passwordHelper {
    position: absolute;
    left: 105%;
    top: 70px;
    background: #fff;
    border: 1px solid #f5f7f8;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 5px;
    padding: 20px 30px 20px 24px;
    width: fit-content;
    width: 363px;
    max-width: 363px;
}

.passwordHelper p {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 10px;
}

.passwordHelper ul {
    padding-left: 30px;
}

.passwordHelper ul li {
    list-style-type: disc;
    color: #8a9399;
    line-height: 160%;
}

.personalData__box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.personalData__box ul {
    width: fit-content;
}

.personalData__box:last-child {
    margin-bottom: 0;
}

.personalData__box label {
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 17px;
}

.requiredPlace {
    position: relative;
}

.requiredPlace::after {
    content: "*";
    color: #e23939;
    font-size: 12px;
    font-size: 14px;
}

.marginLabel {
    display: block;
    margin-top: 20px;
}

.personalData__box__inline__wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.personalData__box__inline__wrap .radio {
    margin-right: 20px;
}

.personalData__box__inline__wrap .radio label {
    margin-right: 8px;
    font-size: 14px;
    font-weight: 400;
}

.personalData__box__inline__wrap .radio label::before {
    top: 2px;
}

.personalData__box__inline__wrap .radio label::after {
    top: 6px;
}

.personalData__box__select {
    justify-content: space-between;
}

.personalData__box__select select {
    width: 31.1%;
}

.snAddBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.snAddBox img {
    display: block;
    margin-right: 20px;
    width: 34px;
    height: 34px;
}

h4.PAsnTitle {
    margin: 60px 0 30px 0;
}

.personalData form:nth-child(2) {
    margin-bottom: 60px;
}

select[name="city_id"] {
    background: #fff;
}

/***PAPERSONALDATA***/

/*PARESUMECOLLAPSED*/
.PAresumeCollapsed {
    position: relative;
    width: 72.73%;
    height: 200px;
    display: flex;
    background: #fff;
    border: 1px solid #f5f7f8;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.07);
    border-radius: 8px;
    padding: 24px 30px;
    transition: var(--transitionTime);
    margin-bottom: 20px;
}

.PAresumeCollapsed:hover {
    box-shadow: 0px 30px 50px rgba(54, 63, 69, 0.22);
}

.PAresumeCollapsed__wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.PAresumeCollapsed__img {
    width: 20.2%;
    border-radius: 5px;
}

.PAresumeCollapsed__img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.PAresumeCollapsed__text {
    display: flex;
    flex-direction: column;
    width: 74.3%;
}

.PAresumeCollapsed__text__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.PAresumeCollapsed__text__title a {
    font-size: 20px;
    font-weight: 700;
    color: var(--Black);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 75%;
}

.PAresumeCollapsed__text__title__edit {
    position: relative;
    display: flex;
    cursor: pointer;
}

.PAresumeCollapsed__text__title__edit a {
    position: relative;
    margin-right: 11px;
    color: var(--sec200);
    font-size: 14px;
    font-weight: 500;
    transition: var(--transitionTime);
}

.PAresumeCollapsed__text__title__edit a::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 1px;
    width: 0;
    transition: var(--transitionTime);
    background: var(--sec200);
}

.PAresumeCollapsed__text__title__edit a:hover {
    color: var(--Black);
}

.PAresumeCollapsed__text__title__edit a:hover:after {
    width: 100%;
}

.PAresumeCollapsed_editBox {
    width: 20px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.PAresumeCollapsed_editBox img {
    transition: var(--transitionTime);
}

.PAresumeCollapsed_editBox:hover img {
    transform: scale(1.2);
}

.PAresumeCollapsed__text__price p,
.PAresumeCollapsed__text__price p span {
    color: var(--sec300);
    font-size: 16px;
    font-weight: 500;
}

.PAresumeCollapsed__text__date {
    align-self: flex-end;
    margin: auto 0 0 0;
}

.PAresumeCollapsed__text__date p,
.PAresumeCollapsed__text__date p span {
    color: var(--sec200);
    font-size: 14px;
    font-weight: 400;
}

/***PARESUMECOLLAPSED***/

/***ADDRESUME***/
.addResumeContent {
    margin-top: 40px;
}

.addVacancy {
    width: 64%;
}

.addVacancy h1 {
    margin-bottom: 37px;
}

p.addVacancyInfo {
    margin-bottom: 30px;
    width: 120%;
    color: var(--sec200);
    font-size: 14px;
    font-weight: 500;
}

p.addVacancyInfo a {
    color: var(--Link);
}

.addResume {
    width: 64%;
}

.addResume h1 {
    margin-bottom: 30px;
}

.priceBox .personalData__box__inline__wrap p {
    margin-right: 10px;
}

.priceBox .container {
    margin-top: 10px;
}

.priceBox input:disabled {
    color: var(--sec100);
}

.skillsAdd__input {
    display: flex;
    margin-bottom: 12px;
}

.skillsAdd__input span {
    height: 42px;
}

.yearEducation {
    flex-direction: row;
    justify-content: space-between;
}

.yearEducation__box {
    width: 48%;
}

.skillsAdd__input {
    justify-content: space-between;
}

.skillsAdd__input input,
.skillsAdd__input select {
    height: 42px;
}

.skillsAdd__input input.Inputerror {
    border: 1px solid var(--pri500);
}

.personalData__box span.checkmark::after {
    top: -5px;
}

.personalData__box__Block {
    margin-bottom: 60px;
}

.langAdd__resultBox {
    display: flex;
    flex-wrap: wrap;
}

.langDoneError {
    display: none;
    font-size: 14px;
    color: var(--pri500);
}

.car_rights__wrap {
    display: flex;
}

.car_rights__box {
    padding-left: 12px;
    padding-top: 4px;
    margin-right: 30px;
}

.car_rights__box .checkmark {
    left: 0;
}

.car_rights__box p {
    z-index: 2;
    color: var(--gr800);
}

.car_rights__box input:checked ~ .checkmark:after {
    content: "";
}

.car_rights__box input:checked ~ p {
    position: relative;
    color: var(--gr50);
}

.car_rights__box .checkmark {
    height: 28px;
}

.addWorkPlaceModal {
    display: none;
    position: fixed;
    top: 5%;
    left: 20%;
    width: 50%;
    height: 85vh;
    overflow-y: scroll;
    background: #fff;
    border-radius: 8px;
    padding: 36px 30px;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    z-index: 8;
}

.addWorkPlaceModal::-webkit-scrollbar {
    width: 14px;
    height: 18px;
}

.addWorkPlaceModal::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
}

.addWorkPlaceModal::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.addWorkPlaceModal::-webkit-scrollbar-corner {
    background-color: transparent;
}

.addWorkPlaceModal .personalData__box:nth-child(2) {
    width: 70%;
}

.addWorkPlaceModal .personalData__box:nth-child(3) {
    width: 70%;
}

.addWorkPlaceModal .personalData__box .personalData__box__inline__wrap {
    justify-content: space-between;
}

.addWorkPlaceModal__btn {
    display: flex;
    justify-content: flex-end;
}

.addWorkPlaceModal__btn .btns {
    margin-left: 10px;
}

.addWorkPlaceModal select {
    width: 48%;
}

.editWorkPlaceModal {
    display: none;
    position: fixed;
    top: 5%;
    left: 20%;
    width: 50%;
    height: 85vh;
    overflow-y: scroll;
    background: #fff;
    border-radius: 8px;
    padding: 36px 30px;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    z-index: 8;
}

.editWorkPlaceModal::-webkit-scrollbar {
    width: 14px;
    height: 18px;
}

.editWorkPlaceModal::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
}

.editWorkPlaceModal::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.editWorkPlaceModal::-webkit-scrollbar-corner {
    background-color: transparent;
}

.editWorkPlaceModal .addWorkPlaceModal__wrap {
    width: 100%;
}

.editWorkPlaceModal .personalData__box:nth-child(2) {
    width: 70%;
}

.editWorkPlaceModal .personalData__box:nth-child(3) {
    width: 70%;
}

.editWorkPlaceModal .personalData__box .personalData__box__inline__wrap {
    justify-content: space-between;
}

.addWorkPlaceModal__btn {
    display: flex;
    justify-content: flex-end;
}

.addWorkPlaceModal__btn .btns {
    margin-left: 10px;
}

.editWorkPlaceModal select {
    width: 48%;
}

.addWorkPlaceModal__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
}

.addWorkPlaceModal__dateError {
    display: none;
    color: #e23939;
    font-size: 12px;
    margin-top: 10px;
}

.canselResumeBTN {
    cursor: pointer;
}

#addPlaceWork {
    display: none;
}

.addPlaceWork__block {
    display: flex;
    flex-direction: column;
}

.addPlaceWork__box {
    position: relative;
    background: #fff;
    border: 1px solid var(--gr300);
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 5px;
    padding: 20px 26px;
    box-sizing: border-box;
    margin-top: 20px;
}

.addPlaceWork__box img {
    position: absolute;
    top: 20px;
    right: 26px;
    cursor: pointer;
}

.workPlaceAfterMpdal__wrap {
    display: flex;
    flex-direction: column;
}

.workPlaceAfterModal__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.workPlaceAfterModal__title p {
    color: var(--redDim);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
}

.workPlaceAfterModal__nameWork {
    margin-bottom: 20px;
}

.workPlaceAfterModal__nameWork p {
    font-size: 14px;
    font-weight: 700;
    color: var(--sec300);
}

.workPlaceAfterModal__date {
    display: flex;
    margin-bottom: 20px;
}

.workPlaceAfterModal__date p {
    font-size: 12px;
    font-weight: 500;
    color: var(--sec300);
}

.workPlaceAfterModal__edit {
    display: none;
}

.workPlaceAfterModal__edit p {
    font-size: 12px;
    font-weight: 400;
    color: var(--sec200);
    cursor: pointer;
}

.resumeInputHidden {
    display: none;
}

.workPlaceAfterModal__title img {
    cursor: pointer;
}

.workPosition {
    margin-bottom: 10px;
}

.skillsAdd__resultBox {
    display: flex;
    flex-wrap: wrap;
}

.addSkillsBox__Block {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    box-sizing: border-box;
    background: #e8ecef;
    border-radius: 5px;
    width: fit-content;
    margin: 0 10px 10px 0;
}

.addSkillsBox__Block p {
    margin-right: 10px;
    font-size: 14px;
}

.addSkillsBox__Block img {
    cursor: pointer;
}

.langAdd__input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}

.langAdd__input select {
    width: 36%;
}

/***ADDRESUME***/

/*PARATEBOX*/
.PArateBox {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    transition: var(--transitionTime);
    border-bottom: 1px solid #f0f3f5;
    margin-bottom: 24px;
}

.PArateBox a {
    margin: 0 0 0 auto;
}

.PArateBox:hover {
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
}

.PArateBox__wrap {
    display: flex;
}

.PArateBox__wrap form {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.PArateBox__left {
    display: flex;
    flex-direction: column;
    margin-right: 5.5%;
    width: 20%;
}

.PArateBox__left span {
    margin-bottom: 10px;
}

.PArateBox__left p,
.PArateBox__left p span {
    color: var(--sec200);
    font-size: 14px;
    font-weight: 500;
}

.PArateBox__center {
    margin-right: 5.5%;
    width: 20%;
}

.PArateBox__center a {
    color: var(--Link);
    margin-bottom: 7px;
}

.PArateBox__center p,
.PArateBox__center p a {
    color: var(--Black);
    font-size: 14px;
    font-weight: 500;
}

.PArateBox__right {
    width: 80%;
}

.PArateBox__right p {
    font-size: 14px;
    font-weight: 400;
    color: var(--sec300);
    margin-right: 12%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.PArateBox__submitBTN {
    position: relative;
    width: fit-content;
    width: 17px;
    height: 21px;
    margin-right: 5px;
    transition: var(--transitionTime);
}

.PArateBox__submitBTN:hover {
    transform: scale(1.1);
}

.PArateBox__submitBTN input {
    display: block;
    position: relative;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: pointer;
}

.PArateBox__submitBTN img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
}

.deleteOffersModal {
    position: fixed;
    top: 30%;
    left: calc(50% - 250px);
    width: 500px;
    max-width: 100%;
    display: none;
    background: #ffffff;
    border-radius: 8px;
    padding: 30px 36px;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgb(54 63 69 / 10%);
    z-index: 15;
}

.ConfirmSubmitModal {
    position: fixed;
    top: 30%;
    left: calc(50% - 250px);
    width: 500px;
    max-width: 100%;
    display: none;
    background: #ffffff;
    border-radius: 8px;
    padding: 30px 36px;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgb(54 63 69 / 10%);
    z-index: 20;
}

.ErrorOfferModal {
    position: fixed;
    top: 30%;
    left: calc(50% - 250px);
    width: 500px;
    max-width: 100%;
    display: none;
    background: #ffffff;
    border-radius: 8px;
    padding: 30px 36px;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgb(54 63 69 / 10%);
}

.deleteOffersModal__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.deleteOffersModal__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
}

.deleteOffersModal__title h4 {
    color: var(--Black);
    margin-bottom: 0 !important;
}

.deleteOffersModal__title img {
    width: 12px;
    height: 12px;
}

.deleteOffersModal__text {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
}

.deleteOffersModal__text img {
    width: 36px;
    height: 39px;
    margin-right: 26px;
}

.deleteOffersModal__text p {
    font-size: 14px;
    font-weight: 400;
    color: var(--sec300);
    line-height: 19.6px;
}

.deleteOffersModal__text__wrap {
    display: flex;
    flex-direction: column;
}

.deleteOffersModal__text__wrap p {
    margin-bottom: 20px;
}

.deleteOffersModal__text__wrap p:last-child {
    margin-bottom: 0;
}

.deleteOffersModal__text__wrap p a {
    color: var(--Black);
    text-decoration: underline;
}

.deleteOffersModal__btn {
    display: flex;
    justify-content: flex-end;
}

.deleteOffersModal__btn .btns:first-child {
    margin-right: 10px;
}

.closeOfferModal {
    cursor: pointer;
}

/***PARATEBOX***/

/*SENDRESUMEMODAL*/
.sendResumeModal {
    position: fixed;
    top: 5%;
    left: 25%;
    display: none;
    width: 50%;
    padding: 30px 36px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    z-index: 16;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0px 0px 30px 0px rgba(50, 50, 50, 0.75);
    box-shadow: 0px 0px 30px 0px rgba(50, 50, 50, 0.75);
}

.sendResumeModal__open {
    display: flex;
}

.sendResumeModal__wrap {
    width: 100%;
}

.sendResumeModal__wrap form {
    display: flex;
    flex-direction: column;
    // margin-bottom: 36px;
}

.sendResumeModal__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
}

.sendResumeModal__title img {
    cursor: pointer;
}

.sendResumeModal__resume {
    background: #ffffff;
    border: 1px solid #f5f7f8;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 5px;
    margin-bottom: 36px;
}

.sendResumeModal__resume__box {
    display: flex;
    flex-direction: column;
    padding: 20px 26px;
    box-sizing: border-box;
}

.sendResumeModal__resume__box h5 {
    margin-bottom: 4px;
    color: var(--Black);
}

.sendResumeModal__resume__box p {
    margin-bottom: 20px;
    color: var(--sec300);
    font-size: 16px;
    font-weight: 400;
}

.sendResumeModal__resume__box p span {
    color: var(--sec300);
    font-size: 16px;
    font-weight: 400;
}

.sendResumeModal__resume__box span {
    color: var(--redDim);
    font-size: 12px;
    font-weight: 400;
}

.sendResumeModal__resumeSelect {
    display: flex;
    flex-direction: column;
    margin-bottom: 26px;
}

.sendResumeModal__resumeSelect label {
    margin-bottom: 14px;
}

.sendResumeModal__resumeSelect select {
    margin-bottom: 8px;
}

.sendResumeModal__resumeSelect a {
    font-size: 12px;
    color: var(--sec200);
    text-decoration: underline;
    display: none;
}

.sendResumeModal__message {
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
}

.sendResumeModal__message label {
    margin-bottom: 14px;
}

.sendResumeModal__message .container {
    margin-bottom: 11px;
}

.sendResumeModal__message .container input:checked textarea {
    display: none;
}

.sendResumeModal__btns {
    display: flex;
    justify-content: flex-end;
}

.sendResumeModal__btns .btns {
    margin-left: 10px;
}

.sendAnswerResumeModal {
    display: none;
    width: 50%;
    padding: 30px 36px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    z-index: 16;
}

.sendResumeModal__radio {
    display: flex;
    justify-content: space-between;
}

.sendResumeModal__radio__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48%;
    border: 1px solid var(--gr300);
    padding: 15px 0;
    box-sizing: border-box;
    margin-bottom: 40px;
}

.sendResumeModal__radio__box .radio {
    margin: 0;
}

.sendResumeModal__radio__box .radio_label:before {
    top: 2px;
}

.sendResumeModal__radio__box .radio_label:after {
    top: 6px;
}

.sendResumeModal__message h5 {
    margin-bottom: 10px;
}

.sendResumeModal .sendResumeModal__title h4 {
    margin-bottom: 0;
}

.sendResumeModal__AllHide {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
}

.sendResumeModal__AllHide img {
    width: 47.72px;
    height: 47.72px;
    margin-right: 26px;
}

.sendResumeModal__AllHide p {
    font-size: 14px;
    font-weight: 400;
    color: var(--sec300);
    line-height: 19.6px;
}

/***SENDRESUMEMODAL***/

/*PERSONALAREA*/
.PAtoggle {
    display: flex;
    width: 100%;
    margin: 40px 0;
}

.PAtoggle__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.PAtoggle__wrap a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24%;
    font-size: 16px;
    font-weight: 500;
    background: var(--gr200);
    transition: var(--transitionTime);
    border-radius: 5px;
}

.offersCount {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -14px;
    right: 19px;
    width: 34px;
    height: 34px;
    box-shadow: 0px 8px 20px rgba(92, 0, 0, 0.28);
    border-radius: 24px;
    background: var(--pri500);
}

.offersCount span {
    color: var(--gr50);
    font-size: 16px;
    font-weight: 400;
}

.PAtoggle__wrap a:hover {
    background: var(--sec500);
}

.PAtoggle__wrap a p {
    font-size: 14px;
}

.PAtoggle__wrap a:hover p {
    color: var(--gr50);
}

.PAtoggle__wrap a:hover svg {
    stroke: var(--gr50);
    fill: var(--gr50);
}

.PAtoggle__wrap a svg {
    margin-right: 20px;
}

.PAtoggle__wrap .PAtoggle__wrap__active {
    background: var(--sec500);
}

.PAtoggle__wrap__active p {
    color: var(--gr50);
}

.PAtoggle__wrap__active svg {
    stroke: var(--gr50);
    fill: var(--gr50);
}

a.backBtn {
    display: flex;
    color: var(--sec200);
    margin-bottom: 30px;
}

a.backBtn img {
    margin-right: 10px;
}

.responsesToggle__box {
    display: flex;
    align-items: center;
    position: relative;
    display: flex;
    width: 100%;
    max-width: 230px;
    height: 62px;
    margin-bottom: 14px;
    box-sizing: border-box;
}

.responsesToggle__box .offersCount {
    width: 34px;
    height: 34px;
    top: 13px;
}

.responsesToggle__box__active {
    background: #c7d2d7;
}

.responsesToggle__box img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.PACompany_responses .resumeCompressBox {
    margin-bottom: 0;
}

.resumeCompressBox__title__info .offersCompanyCompressTitle {
    margin-bottom: 10px;
}

/***PERSONALAREA***/

/*PAGINATION*/
.pagination {
    display: flex;
    margin: 30px 0;
}

.page-link {
    border: none;
    color: var(--Black);
    outline: none;
}

.page-link:hover {
    background: var(--gr300);
    border-radius: 5px;
}

.page-item .page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}

.page-item.active .page-link {
    background: var(--pri500);
    background-color: var(--pri500);
    border-radius: 5px;
    border: none;
    outline: none;
    color: var(--pri50);
    font-weight: 700;
}

.page-item.active .page-link:focus {
    box-shadow: none;
    color: var(--pri50);
}

.page-link:hover {
    color: var(--Black);
}

/***PAGINATION***/

.vacancyOpen__header__company .resumeCompressBox__btn {
    display: none;
}

.vacancyCompressBox__info .resumeCompressBox__btn {
    display: flex;
}

.vacancyCompressBox__info .resumeCompressBox__btn a {
    margin-right: 10px;
}

.vacancyCompressBox__info .resumeCompressBox__btn form {
    margin-right: 10px;
}

.sideBlockFull {
    margin-bottom: 40px;
    margin-top: 40px;
}

.sideBlockFull img {
    width: 100%;
}

.postBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 30px;
}

.postBlock__box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--sec300);
    transition: var(--transitionTime);
    border-radius: 5px;
    padding: 20px 0;
    box-sizing: border-box;
    width: 49%;
}

.postBlock__box img {
    margin-right: 17px;
}

.postBlock__box p {
    color: var(--gr50);
}

.postBlock__box:hover {
    background: var(--sec500);
}

/*ABOUT*/
section.about {
    margin-top: 40px;
}

.about__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
}

.about__info__text {
    width: 49.68%;
}

.about__info__text h4 {
    margin-bottom: 10px;
}

.about__info__text p {
    font-size: 14px;
    color: var(--sec300);
    line-height: 19.6px;
}

.about__info__img {
    width: 46.3%;
}

.about__info__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about__developer {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
}

.about__developer h5 {
    margin-bottom: 24px;
}

.about__developer__wrap {
    display: flex;
}

.about__developer__logo {
    margin-right: 20px;
}

.about__developer__text p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
}

.about__developer__text a {
    text-decoration: underline;
    color: var(--Link);
}

.about__contacts {
    display: flex;
}

.about__contacts__box {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
}

.about__contacts__box span {
    color: var(--sec200);
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
}

.about__contacts__box a {
    color: var(--Black);
    font-size: 16px;
    font-weight: 500;
}

.footer__other__sn {
    display: flex;
}

.footer__other__sn a {
    width: 24px;
    height: 24px;
    margin-right: 14px;
}

.footer__other__sn a img {
    width: 100%;
    height: 100%;
}

/***ABOUT***/

/*PAGES*/
img.absoluteBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    object-fit: cover;
}

.restoreAccess__modal {
    position: absolute;
    top: -150%;
    left: 33.5%;
    background: #fff;
    border-radius: 8px;
    padding: 50px 60px;
    box-sizing: border-box;
    transition: var(--transitionTime);
}

.restoreAccess__modal__open {
    top: 20%;
}

.restoreAccess__modal__wrap {
    display: flex;
    flex-direction: column;
}

.restore__form .login__form_btn {
    display: flex;
    justify-content: flex-end;
}

.restore__form .login__form_btn input {
    margin-left: 10px;
}

.areasOfActivity {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-bottom: 60px;
}

.areasOfActivity h4 {
    margin-bottom: 30px;
    font-weight: 700;
}

.areasOfActivity__wrap {
    display: flex;
    flex-wrap: wrap;
    overflow-y: hidden;
    transition: var(--transitionTime);
}

span.ShowAllCat {
    position: relative;
    color: var(--sec200);
    font-size: 14px;
    font-weight: 500;
    margin: 0 auto;
    margin-top: 38px;
    cursor: pointer;
}

span.ShowAllCat:hover {
    color: var(--sec500);
}

span.ShowAllCat::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 1px;
    background: var(--gr500);
    transition: var(--transitionTime);
}

span.ShowAllCat:hover:after {
    width: 100%;
    background: var(--sec500);
}

.openCatBox {
    height: auto !important;
}

.mainPageImg {
    width: 100%;
    margin: 30px 0;
}

.lastVacancies {
    width: 100%;
}

.lastVacancies .dropContent .dropContent__small {
    margin-right: 0;
}

.vacancyCompressBox {
    position: relative;
}

.lastVacancies .vacancyCompressBox {
    margin-bottom: 20px;
}

.lastVacancies .vacancyCompressBox:last-child {
    margin-bottom: 0;
}

.similarVacancies {
    margin-top: 60px;
}

.resumeCompressBox__fix {
    border: 2px solid var(--pri400);
}

.resumeCompressBox__fix a svg {
    transition: var(--transitionTime);
    stroke: none;
}

.resumeCompressBox__title__info__box a:hover svg {
    transform: scale(1.05);
    stroke: none !important;
}

.dropContent {
    display: flex;
    justify-content: space-between;
}

.dropContent__big {
    width: 70.9%;
}

.dropContent__big h4 {
    margin-right: 10px;
    width: fit-content;
    // -webkit-hyphens: auto;
    // -moz-hyphens: auto;
    // -ms-hyphens: auto;
    // hyphens: auto;
    // word-wrap: break-word;
}

.dropContent__small {
    width: 25.45%;
    // margin-right: 40px;
}

.sideBlock {
    width: 100%;
    margin-bottom: 20px;
}

.sideBlock img {
    width: 100%;
    height: 100%;
}

.PAUserTabs {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.PAUserTabs hr {
    width: 100%;
    height: 1px;
    background: var(--sec50);
    border: none;
    transform: translateY(-1px);
}

.PAUserTabs__wrap {
    display: flex;
}

.PAUserTabs__href {
    padding: 10px 18px;
    box-sizing: border-box;
    background: transparent;
    border-bottom: 2px solid transparent;
}

.PAUserTabs__href__active {
    background: var(--gr100);
    border-bottom: 2px solid var(--pri500);
}

.PAUserTabs__href a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: var(--Black);
}

.PAUserResume .PA__dropContent .dropContent__big {
    width: 70.9%;
}

.PA__dropContent {
    justify-content: flex-start;
}

.PA__dropContent .dropContent__small {
    margin-right: 30px;
}

.PA__dropContent .dropContent__big {
    width: 41%;
}

.PA__ApplicantDropContent .dropContent__big {
    width: 75%;
}

.PAEmployerDropContent {
    justify-content: space-between;
}

.createResumeBox {
    background: #fff;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 8px;
    padding: 50px 60px;
    box-sizing: border-box;
    margin: 50px 0 40px 0;
}

.createResumeBox__wrap {
    display: flex;
    align-items: center;
}

.createResumeBox__wrap img {
    width: 6%;
    margin-right: 4%;
}

.createResumeBox__wrap a {
    margin: 0 0 0 auto;
}

.PAfavorit__wrap {
    width: 72.73%;
}

.PAUserResume {
    display: flex;
    flex-direction: column;
}

.PAUserResume hr {
    width: 100%;
    height: 1px;
    border: none;
    background: var(--gr300);
    margin: 30px 0;
}

.PAresumeCollapsed__modal {
    display: none;
    position: absolute;
    top: 35px;
    left: -5px;
    background: #ffffff;
    border: 1px solid #f5f7f8;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
}

.PAresumeCollapsed__modal__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.PAresumeCollapsed__modal__wrap label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 25px;
    box-sizing: border-box;
    cursor: pointer;
    transition: var(--transitionTime);
}

.PAresumeCollapsed__modal__wrap label p {
    margin: 0;
}

.PAresumeCollapsed__modal__wrap label img {
    margin-left: 12px;
}

.PAresumeCollapsed__modal__wrap label:last-child img {
    margin-bottom: 0;
}

.PAresumeCollapsed__modal__wrap label:hover {
    background: var(--pri500);
    color: var(--gr50);
}

.PAresumeCollapsed__modal__open {
    display: block;
}

.PArateBox__left__all {
    padding: 13px 18px;
    box-sizing: border-box;
    cursor: default;
    border-radius: 4px;
    font-size: 14px;
    border: none;
    width: fit-content;
    white-space: nowrap;
    text-align: center;
}

.PArateBox__left_wait {
    background: var(--gr300);
    color: var(--sec300);
}

.PArateBox__left_invite {
    background: var(--green50);
    color: var(--green600);
}

.PArateBox__left_refusal {
    background: var(--pri100);
    color: var(--pri600);
}

.PAcompanyResponsesCompress {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.PAcompanyResponses__box {
    position: relative;
    display: flex;
    background: #ffffff;
    border: 1px solid #f5f7f8;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 19px 26px;
    width: 100%;
    margin-bottom: 24px;
}

.PAcompanyResponses__box__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.PAcompanyResponses__box__price {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.PAcompanyResponses__box__price a {
    display: none;
}

.PAcompanyResponses__box__price p {
    background: #f5f7f8;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 500;
    color: var(--sec300);
}

.PAcompanyResponses__box__count {
    margin: 20px 0;
}

.PAcompanyResponses__box__count p {
    font-size: 18px;
    font-weight: 500;
    color: var(--sec300);
}

.PAcompanyResponses__box__active {
    box-shadow: 0px 30px 50px rgba(54, 63, 69, 0.22);
}

.PAcompanyResponses__box__active::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 101%;
    background: var(--pri500);
    -moz-border-radius-topright: 20px;
    -webkit-border-top-right-radius: 20px;
    border-top-right-radius: 20px;
    -moz-border-radius-bottomright: 20px;
    -webkit-border-bottom-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.companyBox__compress {
    display: flex;
    background: #fff;
    border: 1px solid #f5f7f8;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 8px;
    padding: 24px 30px;
    color: var(--Black);
}

.companyBox__compress__wrap {
    display: flex;
    flex-direction: column;
}

.companyBox__compress__title {
    display: flex;
    width: 100%;
    margin-bottom: 24px;
}

.companyBox__compress__title_img {
    width: 9.7%;
    height: fit-content;
    margin-right: 24px;
}

.companyBox__compress__title_img img {
    width: 100%;
    height: 100%;
}

.companyBox__compress__title .resumeCompressBox__title__favoriteIcon {
    margin: 0 0 0 auto;
}

.companyBox__compress__title_text h4 {
    margin-bottom: 14px;
}

.companyBox__compress__title_text span {
    color: var(--sec200);
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
}

.companyBox__compress__text {
    margin-bottom: 24px;
}

.companyBox__compress__text p {
    font-size: 14px;
    font-weight: 400;
    color: var(--sec300);
}

.companyBox__compress__quantity {
    display: flex;
    justify-content: space-between;
    background: #f5f7f8;
    border: 1px solid #f0f3f5;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 14px 20px;
}

.companyBox__compress__quantity p {
    font-size: 14px;
    font-weight: 700;
}

.companyBox__compress__quantity span {
    font-size: 16px;
    font-weight: 700;
}

input.hiddenInput {
    display: none;
}

.vacancyOpen__header .resumeCompressBox__btn {
    justify-content: flex-start;
    align-items: center;
}

.vacancyOpen__header .resumeCompressBox__btn span {
    margin-right: 20px;
}

.vacancyOpen__header
    .resumeCompressBox__btn
    .resumeCompressBox__title__favoriteIcon {
    display: flex;
}

.vacancyOpen__header
    .resumeCompressBox__btn
    .resumeCompressBox__title__favoriteIcon
    svg {
    margin-right: 10px;
}

.vacancyOpen__header__company {
    display: flex;
    justify-content: flex-end;
    width: 35%;
}

.vacancyOpen__header__company__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.vacancyOpen__header__company__wrap img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    object-fit: cover;
}

.vacancyOpen__header__company__wrap h5 {
    margin-bottom: 4px !important;
}

.vacancyOpen__header__company__wrap a {
    width: fit-content;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
    color: var(--Black);
}

.vacancyOpen__header .resumeCompressBox__title__info p {
    color: var(--sec300);
    font-size: 24px;
    margin-top: 14px;
}

.vacancyOpen__header .vacancyCompressBox__info {
    width: 70%;
}

.vacancyOpenBox {
    display: flex;
    flex-wrap: wrap;
}

.vacancyOpen__block .videoBlock__show {
    width: 60%;
}

.vacancyOpen__block h5 {
    margin-bottom: 14px;
    font-weight: 700;
}

.vacancyOpen__block p {
    font-size: 14px;
    line-height: 140%;
    color: var(--sec300);
    margin: 5px 0;
}

.vacancyOpen__block p strong {
    font-size: 16px;
    font-weight: 700;
    color: var(--Black);
}

.vacancyOpen__block p a {
    color: var(--Link);
    font-size: 14px;
}

.vacancyOpen__block ol {
    padding-left: 2%;
    margin: 10px 0;
}

.vacancyOpen__block ol li {
    list-style-type: decimal;
    font-size: 14px;
    line-height: 140%;
    color: var(--sec300);
    line-height: 140%;
    margin-bottom: 6px;
}

.vacancyOpen__block ol li:last-child {
    margin-bottom: 0;
}

.vacancyOpen__block ul {
    padding-left: 2%;
    box-sizing: border-box;
}

.vacancyOpen__block ul li {
    position: relative;
    line-height: 140%;
    margin-bottom: 6px;
    color: var(--sec300);
    font-size: 14px;
}

.vacancyOpen__block ul li:last-child {
    margin-bottom: 0;
}

.vacancyOpen__block ul li::before {
    content: "";
    position: absolute;
    left: -1.8%;
    bottom: 5px;
    border: 5px solid transparent;
    border-left: 5px solid var(--pri500);
}

.vacancyOpen__block {
    margin-bottom: 30px;
}

.vacancyOpen__block.resumeCompressBox__btn {
    justify-content: flex-start;
    align-items: center;
}

.vacancyOpen__block.resumeCompressBox__btn span {
    margin-right: 20px;
}

.vacancyOpen__block.resumeCompressBox__btn
    .resumeCompressBox__title__favoriteIcon {
    display: none;
}

.vacancyOpen__block.resumeCompressBox__btn
    .resumeCompressBox__title__favoriteIcon
    svg {
    margin-right: 10px;
}

.vacancyOpen__block.vacancyOpen__tel {
    display: flex;
    flex-direction: column;
}

.vacancyOpen__block.vacancyOpen__tel a {
    margin-bottom: 10px;
    color: var(--Link);
    width: fit-content;
}

.resumeOpen {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.resumeOpen__img {
    width: 25.6%;
}

.resumeOpen__img img {
    width: 100%;
    border-radius: 5px;
}

.resumeOpen__info {
    width: 67.4%;
}

.resumeOpen__box_inline {
    display: flex;
}

.resumeOpen__box {
    margin-bottom: 30px;
}

.resumeOpen__box ol {
    margin: 10px 0;
}

.resumeOpen__box ol li {
    list-style-type: decimal;
}

.resumeOpen__box h6 {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 14px;
}

.resumeOpen__box p {
    font-size: 16px;
    color: var(--sec300);
    font-weight: 400;
    line-height: 140%;
    margin: 5px 0;
}

.resumeOpen__box p {
    font-size: 14px;
    line-height: 140%;
    color: var(--sec300);
}

.resumeOpen__box p strong {
    font-size: 16px;
    font-weight: 700;
    color: var(--Black);
}

.resumeOpen__box p a {
    color: var(--Link);
    font-size: 14px;
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.resumeOpen__box ol {
    padding-left: 2%;
}

.resumeOpen__box ol li {
    list-style-type: decimal;
    font-size: 14px;
    line-height: 140%;
    color: var(--sec300);
    line-height: 140%;
    margin-bottom: 6px;
}

.resumeOpen__box ol li:last-child {
    margin-bottom: 0;
}

.resumeOpen__box ul {
    padding-left: 2%;
    box-sizing: border-box;
}

.resumeOpen__box ul li {
    position: relative;
    line-height: 140%;
    margin-bottom: 6px;
    color: var(--sec300);
    font-size: 14px;
}

.resumeOpen__box ul li:last-child {
    margin-bottom: 0;
}

.resumeOpen__box ul li::before {
    content: "";
    position: absolute;
    left: -1.8%;
    bottom: 5px;
    border: 5px solid transparent;
    border-left: 5px solid var(--pri500);
}

.resumeOpen__box_inline p {
    margin-right: 8px;
    color: var(--sec300);
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
}

.resumeOpen__vacancies {
    display: flex;
    margin-bottom: 20px;
}

.resumeOpen__vacancies__date {
    display: flex;
    margin-right: 20px;
}

.resumeOpen__vacancies__date p {
    margin-right: 3px;
    color: var(--sec200);
}

.resumeOpen__vacancies__info {
    display: flex;
    flex-direction: column;
}

.resumeOpen__vacancies__info span {
    font-size: 14px;
    font-weight: 400;
    background: var(--gr300);
    padding: 6px 10px;
    box-sizing: border-box;
    margin-bottom: 8px;
    width: fit-content;
}

.resumeOpen__vacancies__info h5 {
    margin-bottom: 4px;
}

.resumeOpen__vacancies__info p {
    color: var(--sec200);
    font-size: 14px;
}

.resumeOpen__box__contacts {
    display: flex;
    flex-direction: column;
}

.resumeOpen__box__contacts p {
    color: var(--sec200);
    font-size: 16px;
    margin-bottom: 6px;
}

.resumeOpen__box__contacts a {
    color: var(--Link);
    text-decoration: underline;
}

.resumeOpen__box__contacts__title {
    display: flex;
    margin-bottom: 10px;
}

.resumeOpen__box__contacts__title h6 {
    margin-right: 10px;
    margin-bottom: 0;
}

.resumeOpen__box__contacts__title span {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500px;
}

.resumeOpen__box__contacts__contact {
    display: none;
}

.resumeOpen__box__contacts__contact__open {
    display: block;
}

.resumeOpen__box_inline a {
    margin-right: 14px;
    width: 34px;
    height: 34px;
    transition: var(--transitionTime);
}

.resumeOpen__box_inline a:hover {
    transform: scale(1.2);
}

.resumeOpen__box_inline a img {
    width: 100%;
    height: 100%;
}

.resumeOpenCurrent__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.resumeOpenCurrent__title span {
    display: flex;
    padding: 8px 12px;
    box-sizing: border-box;
    background: var(--gr300);
    height: fit-content;
}

.resumeOpenCurrent__box {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.resumeOpenCurrent__box__inline {
    display: flex;
}

.resumeOpenCurrent__box p {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
}

.resumeOpenCurrent__box span {
    padding: 8px 12px;
    box-sizing: border-box;
    background: var(--gr300);
    width: fit-content;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
}

.workPlace__place__left {
    width: 29%;
}

.workPlace__place__right {
    width: 68%;
}

p.generalTumeWork {
    margin: 50px 0 30px 0;
    font-size: 20px;
}

.resumeOpenCurrent__workPlace {
    margin-bottom: 40px;
}

.resumeOpenCurrent__workPlace__box {
    display: flex;
    flex-direction: column;
    background: #e8ecef;
    border: 1px solid #d5dde1;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 14px 20px;
    margin-bottom: 20px;
}

.resumeOpenCurrent__workPlace__box p,
.resumeOpenCurrent__workPlace__box p span {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 7px;
    color: var(--Black);
}

.resumeOpenCurrent__workPlace__box span {
    color: var(--sec200);
    font-size: 14px;
}

.resumeOpenCurrent__workPlace__place {
    display: flex;
}

.resumeOpenCurrent__workPlace__place span {
    display: block;
    color: var(--sec200);
    margin-bottom: 6px;
}

.resumeOpenCurrent__workPlace__place h3 {
    margin-bottom: 25px;
    font-size: 20px;
}

.resumeOpenCurrent__workPlace__place p {
    color: var(--sec300);
    font-size: 14px;
}

.resumeOpenCurrent__box h4 {
    margin-bottom: 20px;
}

.resumeOpenCurrent__box__inline span {
    margin-right: 10px;
}

.lastVacancies hr {
    width: 100%;
    height: 1px;
    background: var(--sec50);
    margin: 40px 0;
    border: none;
}

.resumeList {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
}

.resumeList h4 {
    margin-bottom: 30px;
}

.cke_button__about {
    display: none !important;
}

.resumeCompressBox__btn object {
    display: flex;
}

.resumeCompressBox__btn object a {
    margin-right: 10px;
}

.userResponses_open__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 26px;
}

.userResponses_open__title h5 {
    margin-bottom: 10px;
    color: var(--Link);
}

.userResponses_open__title p {
    font-weight: 50;
    font-size: 14px;
}
.userResponses_open__title p a {
    font-weight: 600;
}

.userResponses_open__subtitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}

.userResponses_open__subtitle p {
    color: var(--sec200);
    font-size: 14px;
}

.userResponses_open__subtitle a {
    font-size: 14px;
    font-weight: 500;
    color: var(--Link);
}

.userResponses_open__subtitle span {
    color: var(--sec300);
    font-size: 14px;
    font-weight: 500;
}

.userResponses_open__box {
    background: #ffffff;
    border: 1px solid #f5f7f8;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 5px;
    padding: 20px 30px;
    margin-bottom: 90px;
}

.userResponses_open__box__wrap {
    display: flex;
    flex-direction: column;
}

.userResponses_open__box__wrap p {
    color: var(--sec300);
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 400;
}

.userResponses_open__box__wrap span {
    color: var(--sec200);
    font-size: 14px;
    font-weight: 400;
}

.writeResumeEducation__box {
    display: flex;
    flex-direction: column;
}

span.addEducationBox {
    position: relative;
    display: block;
    color: var(--sec200);
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    transition: var(--transitionTime);
    width: fit-content;
}

span.addEducationBox::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 1px;
    background: var(--Black);
    transition: var(--transitionTime);
}

span.addEducationBox:hover {
    color: var(--Black);
}

span.addEducationBox:hover:after {
    width: 100%;
}

.writeResumeEducation__box__wrap hr {
    width: 100%;
    height: 1px;
    background: var(--sec100);
    margin: 20px 0;
    border: none;
}

.writeResumeEducation__box__wrap {
    margin-bottom: 10px;
}

.deleteResume {
    display: flex;
}

.deleteResume img {
    margin-bottom: 0;
}

.downloadResume {
    padding: 0 !important;
}

.downloadResume a {
    display: flex;
    color: var(--sec200);
    font-size: 14px;
    font-weight: 500;
    margin-right: 0;
    padding: 15px 25px;
}

.downloadResume a p {
    color: var(--Black);
}

.downloadResume a:hover {
    color: var(--gr50);
}

.downloadResume:hover p {
    color: var(--gr50);
}

.downloadResume a:hover:after {
    width: 0;
}

.PAresumeCollapsed__text__btnMobil {
    display: none;
}

.vacancyCompressBox__tags {
    margin-bottom: 12px;
}

.vacancyCompressBox__tags span:first-child {
    background: var(--pri500);
    color: var(--gr50);
}

.vacancyCompressBox__tags span:first-child:hover {
    box-shadow: none;
    cursor: default;
}

.responsesMobilArr {
    display: none;
}

.resumeCompressBox__textUnder {
    position: relative;
    display: block;
    margin-bottom: 20px;
    background: #fff;
    border: 1px solid #f0f3f5;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 8px;
    padding: 20px 26px;
    height: 73px;
    overflow-y: hidden;
    transition: var(--transitionTime);
}

.resumeCompressBox__textUnder__open {
    position: relative;
    height: auto;
    transition: var(--transitionTime);
}

.resumeCompressBox__textUnder__wrap {
    display: flex;
    flex-direction: column;
}

.resumeCompressBox__textUnder_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.resumeCompressBox__textUnder_title span {
    position: relative;
    font-size: 14px;
    color: var(--sec300);
    cursor: pointer;
}

.resumeCompressBox__textUnder_title span::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: var(--sec300);
    transition: var(--transitionTime);
}

.resumeCompressBox__textUnder_title span:hover:after {
    width: 100%;
}

.resumeCompressBox__textUnder_text p {
    font-size: 14px;
    line-height: 140%;
    color: var(--sec300);
    outline: none;
}

.mobilMenu {
    display: none;
}

.supportBlock {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 70px;
    height: 70px;
    // transition: var(--transitionTime);
    cursor: pointer;
    z-index: 16;
}
.supportBlock__wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    height: 70px;
    background: var(--gr50);
    border: 2px solid var(--gr500);
    border-radius: 50%;
}
.supportTrigger {
    width: 100%;
    background: var(--gr400);
    border-radius: 50%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    z-index: 2;
}
.suuportTrigger img {
    width: 100%;
}
.supportElement {
    z-index: 1;
    position: absolute;
    bottom: 12%;
    left: 14%;
    width: 70%;
    transition: var(--transitionTime);
}
.supportElement:hover {
    transform: scale(1.1);
}
.supportElement img {
    width: 100%;
}
.supportBlock:hover {
    bottom: 180px;
}
.supportBlock:hover .supportBlock__wrap {
    height: 220px;
    border-radius: 90px;
}
.supportBlock:hover .supportMail {
    bottom: 43%;
    // z-index: 2;
}
.supportBlock:hover .supportTg {
    bottom: 70%;
    // z-index: 2;
}
// .support:hover {
//     width: 214px;
//     background-repeat: 52px;
// }

// .support p {
//     color: var(--Black);
// }

// .support:hover p {
//     width: 114px;
//     margin-right: 20px;
//     text-align: right;
// }

.errorPage {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    min-height: 26vh;
}

.errorPage__wrap {
    display: flex;
    width: 100%;
    justify-content: center;
}

.errorPage__img {
    margin-right: 80px;
}

.errorPage__text {
    width: 30%;
}

.errorPage__text p {
    font-size: 16px;
    line-height: 140%;
    color: #535c62;
    margin-bottom: 50px;
}

.errorPage__text p:first-child {
    font-size: 23px;
    line-height: 140%;
    color: #8a9399;
    margin-bottom: 0;
}

.errorPage__text span {
    font-size: 126px;
    line-height: 140%;
    color: #252d35;
    margin-bottom: 16px;
    font-weight: 700;
}

.pass__wrap {
    position: relative;
}

img.showPassBtn {
    position: absolute;
    bottom: 12px;
    right: 17px;
    cursor: pointer;
}

.login img.showPassBtn {
    bottom: 30px;
}

.register img.showPassBtn {
    bottom: 12px;
}

.showPassBtn_open {
    display: block;
}

.showPassBtn_close {
    display: none;
}

.employer__offersSmall {
    position: relative;
    margin-right: 40px;
}

.employer__offersSmall::after {
    content: "";
    position: absolute;
    top: 0;
    right: -7%;
    width: 1px;
    height: 100%;
    background: var(--gr700);
}

/***PAGES***/

/*SUPPORT*/
section.supports {
    margin-bottom: 65px;
    margin-top: 40px;
}

section.supports .support__wraps {
    width: 40%;
    margin-bottom: 60px;
}

section.supports .support__wraps {
    display: flex;
    flex-direction: column;
}

section.supports .support__wraps h2 {
    margin-bottom: 20px;
    color: var(--Black);
}

section.supports .support__wraps p {
    font-size: 14px;
    color: var(--sec300);
    margin-bottom: 40px;
}

/***SUPPORT***/

/*PDF*/
.pdf__wrap {
    width: 88.97%;
    margin: 0 auto;
}

section.pdf {
    background: #fff;
}

section.pdf h5 {
    margin-bottom: 34px;
}

.pdfHeader__wrap {
    display: flex;
    justify-content: space-between;
}

.pdfMainInfo__wrap {
    display: flex;
    justify-content: space-between;
}

.pdfMainInfo__img {
    width: 21.25%;
    border-radius: 10px;
}

.pdfMainInfo__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.pdfMainInfo__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 73.39%;
}

.pdfMainInfo__text__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
}

.pdfMainInfo__text__title__wrap {
    display: flex;
}

.pdfMainInfo__text__contacts__wrap p {
    color: var(--gr900);
}

.pdfMainInfo__text__title h1 {
    margin-bottom: 10px;
}

.pdfMainInfo__text__title__wrap p {
    margin-right: 10px;
    color: var(--gr900);
}

.pdfMainInfo__text__sn__box {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.pdfMainInfo__text__sn__box img {
    margin-right: 10px;
}

.pdfMainInfo__text__sn__box a {
    color: var(--gr900);
    text-decoration: underline;
}

.pdfTitle_red {
    display: flex;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 80px;
}

.pdfTitle_red p {
    color: var(--pri500);
    font-size: 30px;
    white-space: nowrap;
    margin-right: 3%;
}

.pdfTitle_red hr {
    width: 100%;
    height: 1px;
    background: var(--pri500);
}

.pdfVacancies__wrap {
    display: flex;
    flex-direction: column;
}

.pdfVacancies__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    background: var(--gr100);
    margin-bottom: 40px;
}

.pdfVacancies__title h1 {
    margin-left: 3%;
}

.pdfVacancies__title span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    height: 100%;
    background: var(--sec400);
    color: var(--gr100);
    padding: 0 3%;
}

.pdfVacancies__boxes {
    display: flex;
    flex-wrap: wrap;
}

.pdfVacancies__boxes__box {
    display: flex;
    flex-direction: column;
}

.pdfVacancies__boxes__box:nth-child(1),
.pdfVacancies__boxes__box:nth-child(2),
.pdfVacancies__boxes__box:nth-child(3) {
    width: 30%;
    margin-bottom: 30px;
}

.pdfVacancies__boxes__box:nth-child(4),
.pdfVacancies__boxes__box:nth-child(5) {
    width: 50%;
}

.pdfVacancies__boxes__box h4 {
    margin-bottom: 20px;
}

.pdfVacancies__boxes__box__wrap {
    display: flex;
    flex-wrap: wrap;
}

.pdfWorkExp__wrap {
    display: flex;
    flex-direction: column;
}

.pdfWorkExp__box {
    display: flex;
    flex-direction: column;
}

.pdfWorkExp__box__title {
    display: flex;
    flex-direction: column;
    background: var(--gr300);
    padding: 20px 0 20px 1%;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 30px;
}

.pdfWorkExp__box__title p {
    font-size: 20px;
    margin-bottom: 8px;
}

.pdfWorkExp__box__title span {
    font-size: 18px;
}

.pdfWorkExp__box__place {
    display: flex;
    justify-content: space-between;
}

.pdfWorkExp__box__place span {
    color: var(--sec200);
    font-size: 18px;
    margin-bottom: 10px;
    display: block;
}

.pdfWorkExp__box__place h4 {
    display: block;
    margin-bottom: 10px;
}

.pdfWorkExp__box__place p {
    font-size: 20px;
    color: var(--sec200);
}

.pdfWorkExp__box__place__left {
    width: 20.09%;
    margin-right: 20px;
}

.pdfWorkExp__box__place__right {
    width: 77.68%;
}

.pdfEducation__box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.pdfEducation__box__date {
    width: 13.39%;
}

.pdfEducation__box__text {
    width: 83.48%;
}

.pdfEducation__box__text span {
    margin-bottom: 20px;
}

section.pdf .pdfEducation__box__text h5 {
    margin-bottom: 10px;
}

.pdfEducation__box__text p {
    color: var(--sec200);
}

.pdfAbout__text {
    display: flex;
    flex-direction: column;
}

.pdfAbout__text h4 {
    margin-bottom: 20px;
}

.pdfAbout__text p {
    color: var(--sec200);
}

/***PDF***/

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-top: 100px;
}

.card .card-header {
    margin-bottom: 50px;
}

.card .ifNotrecive {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

.card .ifNotrecive p {
    margin-bottom: 20px;
}

.header__account__user__mobil {
    display: none;
}

.header__account__user__desctop {
    display: flex;
    align-items: center;
}

/*HIDE RESUME/VACANCIES BLOCK*/
.resumeVacanciesHideBlock {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    width: 72%;
    height: 100%;
    background: linear-gradient(
        90deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.71) 46.88%,
        rgba(255, 255, 255, 0) 100%
    );
    border-radius: 8px;
}

.resumeVacanciesHideBlock__active .resumeVacanciesHideBlock {
    display: flex;
}

.resumeVacanciesHideBlock__disabled .resumeVacanciesHideBlock {
    display: none;
}

.eyeHideIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 68px;
    background: var(--gr300);
    border: 6px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 36px;
    transform: translateX(-34px);
}

.eyeHideIcon img {
    width: 26px;
    height: 26px;
}

/***HIDE RESUME/VACANCIES BLOCK***/

/*SELECT STATUS*/
.selectStatus__current {
    display: flex;
}

.selectStatus__current img {
    margin-right: 0;
    margin-left: 10px;
}

.selectStatus__current p {
    white-space: nowrap;
}

.selectStatus {
    cursor: pointer;
}

.PAavatar__substrate__box,
.changeStatus {
    transform: translateY(60px);
    transition: var(--transitionTime);
}

.userStatusOpen {
    transform: translateY(0px);
}

.changeStatus {
    margin-top: 16px;
}

.changeStatus__box {
    display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 4px;
    border-radius: 4px;
    transition: var(--transitionTime);
    cursor: pointer;
}

.changeStatus__box:hover {
    background: rgba(255, 255, 255, 0.2);
}

.changeStatus__active .status__NoWork {
    display: flex;
}

.changeStatus__active .status__GoWork {
    display: none;
}

.changeStatus__disabled .status__NoWork {
    display: none;
}

.changeStatus__disabled .status__GoWork {
    display: flex;
}

.changeStatus__box p {
    color: var(--gr50);
    font-size: 14px;
    font-weight: 500;
}

.changeStatus__wrap hr {
    width: 63px;
    height: 1px;
    border: none;
    background: rgba(255, 255, 255, 0.26);
    margin: 0 auto;
}

.userStatusIcon {
    display: none;
}

/***SELECT STATUS***/

/* MAILINGS */
.mailingTitle {
    margin: 50px 0;
}

.mailingTable {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid grey;
}

.mailingTable tr,
.mailingTable td {
    border: 1px solid grey;
    transition: var(--transitionTime);
}

.mailingTable tr {
    width: 100%;
}

.mailingTable tr td:nth-child(2) {
    max-width: 30%;
    width: 30%;
}

.mailingTable td {
    padding: 10px 5px;
    cursor: pointer;
}

.mailingTable tr:not(:first-child):hover {
    background: rgb(221, 221, 221);
}

.mailingTable__title {
    background: grey;
}

.mailing__buttons {
    display: flex;
    width: 100%;
    justify-content: center;
}

.mailing__buttons .colorBtn {
    margin-right: 10px;
}

.mailing__buttons .btns {
    padding: 5px 10px;
}

.personalData__box__imgs {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.personalData__box__imgs img {
    width: 20%;
}

.currentMailing__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.currentMailing__block .personalData__box {
    margin-bottom: 50px;
    width: 33%;
}

.currentMailing__block .btns {
    cursor: default;
    pointer-events: none;
}

.mailingEndBtn {
    background: var(--pri400);
}

.mailingInBtn {
    background: var(--green400);
}

.mailingWaitBtn {
    background: --sec400;
}

.mailingTable td p {
    display: none;
}

.mailingTable td span {
    width: 100%;
}

.mailingTable td a {
    display: flex;
    width: 100%;
    height: 100%;
}

.createMailing__position span {
    border-radius: 50%;
}

.personalData__box__mailingInline__wrap {
    display: flex;
    align-items: center;
}

.personalData__box__mailingInline__wrap input {
    width: 80px;
}

.personalData__box__mailingInline__wrap span {
    margin-right: 10px;
}

.mailingCreate__wrap {
    display: none;
}

.mailingCreate__open {
    display: block;
}

.mailingList input[type="submit"] {
    margin-top: 30px;
}

/*** MAILINGS ***/

/* COMPANIES */
.Companies {
    margin-top: 40px;
}

.companiesList__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.companiesList__box {
    width: 45.87%;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}
.companiesList__box.close {
    margin-bottom: 10px;
}

.companiesList__box__list {
    display: flex;
    flex-direction: column;
    transition: 0.3s;
}

.companiesList__box__list.close {
    height: 0;
    overflow: hidden;
}

.companiesList__box__list a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: fit-content;
}

.companiesList__box__list a:hover p {
    font-weight: 600;
}

.companiesList__box__list a:hover span {
    color: var(--Black);
}

.companiesList__box__list a p {
    color: var(--Black);
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 8px;
    transition: var(--transitionTime);
}

.companiesList__box__list a span {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--sec100);
    transition: var(--transitionTime);
}

.companiesList__box__title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
}
.companiesList__box__title svg {
    width: 15px;
    height: 15px;
    opacity: 0.3;
    transition: var(--transitionTime);
}
.companiesList__box__title:hover svg {
    opacity: 1;
}
/*** COMPANIES ***/

/* CURRENT COMPANY */
.company__info {
    display: flex;
    margin-bottom: 40px;
}

.company__info__avatar {
    width: 19.28%;
    margin-right: 50px;
}

.company__info__avatar img {
    width: 100%;
    height: 100%;
}

.company__info__text {
    width: 74.29%;
}

.company__info__text h3 {
    margin-bottom: 12px;
}

.company__info__text span.company__adress {
    margin-bottom: 30px;
    color: var(--sec200);
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 30px;
}

.company__info__text__inline {
    display: flex;
}

.company__info__text__box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.company__info__text__box:last-child {
    margin-bottom: 0;
}

.company__info__text__inline .company__info__text__box:first-child {
    margin-right: 30px;
}
.company__info__text__box span {
    color: var(--sec200);
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 5px;
}

.company__info__text p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: black;
}
.companyNumber {
    width: 60%;
}

/* OREDERBY */
.orederBy__wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.orederBy__text,
.orederBy__selects,
.orederBy__selects__wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.orederBy__text {
    margin-bottom: 4px;
}

.orederBy__text p {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: var(--Black);
}

.orederBy__text span {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: var(--sec200);
}

.orederBy__selects select {
    display: none;
}

.orederBy__selects input[type="submit"] {
    display: none;
}

.orderBy__selectBox {
    position: relative;
    z-index: 1;
}

.orderBy__selectBox__target {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.orderBy__selectBox__outwardly {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.orderBy__selectBox__outwardly p {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: var(--Link);
    margin-right: 6px;
}

.orderBy__selectBox__selectBox {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    white-space: nowrap;
    background: #ffffff;
    border: 1px solid var(--gr100);
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 5px;
    padding: 8px 0;
    box-sizing: border-box;
}

.orderBy__selectBox__selectBox__active {
    display: flex;
}

.orderBy__selectBox__selectBox span {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--sec200);
    margin-bottom: 5px;
}

.company__info__text a {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--Link);
}

.currentCompany {
    margin-top: 40px;
}

.company__about {
    margin-bottom: 40px;
}

.company__about h5 {
    margin-bottom: 14px;
}

.company__about p {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--sec300);
}

.company__vacancies__title {
    display: flex;
    margin-bottom: 30px;
}

.company__vacancies__title h4 {
    margin-bottom: 0;
    margin-right: 10px;
}

.company__vacancies__title span {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: var(--gr600);
}

/*** CURRENT COMPANY ***/
.orderBy__selectBox__selectBox span {
    padding: 8px 20px;
    box-sizing: border-box;
    cursor: pointer;
    transition: var(--transitionTime);
}

.orderBy__selectBox__selectBox span:hover {
    color: var(--Black);
}

/*** OREDERBY ***/

/* POLICY */
.privacyPolicy span {
    display: block;
    font-size: 20px;
    color: var(--Black);
    font-weight: 700;
    margin: 30px 0;
}
.privacyPolicy p {
    font-size: 14px;
    line-height: 140%;
    color: var(--sec300);
    margin-bottom: 10px;
}
.privacyPolicy ol li {
    list-style-type: decimal;
    padding-left: 20px;
}
.privacyPolicy {
    margin: 40px 0;
}
/*** POLICY ***/

/* NEWS LIST */
.newsList {
    margin-top: 20px;
}
.newsList__titleSearch {
    margin-bottom: 30px;
}
.newsList__wrap {
    display: flex;
    flex-direction: column;
}
.newsList__titleSearch {
    display: flex;
    justify-content: space-between;
}
.newsList__search {
    width: 42.45%;
}
.newsList__ListWrap {
    display: flex;
    flex-direction: column;
}
.newsList__box {
    display: flex;
    width: 100%;
    border: 2px solid var(--gr100);
    transition: var(--transitionTime);
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
}
.newsList__box:hover {
    box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.3);
}
.newsList__box__img {
    width: 42.45%;
    border-radius: 10px 0 0 10px;
}
.newsList__box__img img {
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    object-fit: cover;
}
.newsList__box__text {
    display: flex;
    flex-direction: column;
    width: 60.24%;
    padding: 3.91% 5.87% 4.89% 5.87%;
    box-sizing: border-box;
    background: #fff;
    border-radius: 0 10px 10px 0;
}
.newsList__box__text__title p {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: var(--Black);
}
.newsList__box__text hr {
    width: 100%;
    height: 1px;
    border: none;
    background: var(--gr300);
    margin: 18px 0;
}
.newsList__box__text__data {
    display: flex;
    justify-content: space-between;
}
.newsList__box__text__data__date {
    display: flex;
}
.newsList__box__text__data svg {
    margin-right: 8px;
}
.newsList__box__text__data span {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: var(--sec200);
}
.articles__small__list {
    display: flex;
    flex-direction: column;
}
.articles__small__list h3 {
    margin-bottom: 30px;
}
.articles__small__list__wrap {
    display: flex;
    flex-direction: column;
}
.articles__small__list__box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    transition: var(--transitionTime);
}
.articles__small__list__box:hover .articles__small__list__img {
    width: 30%;
}
.articles__small__list__img {
    width: 26.95%;
    min-width: 100px;
    height: 100px;
    margin-right: 24px;
    transition: var(--transitionTime);
}
.articles__small__list__img img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
}
.articles__small__list__text {
    width: 66.58%;
}
.articles__small__list__text p {
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: var(--Black);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.articles__small__list__text span {
    font-weight: 500;
    font-size: 14px;
    text-decoration-line: underline;
    line-height: 140%;
    color: var(--pri500);
}
.articles__small__list__wrap hr {
    width: 100%;
    height: 1px;
    border: none;
    background: var(--sec50);
    margin: 20px 0;
}
.articles__small__list__more {
    display: flex;
    justify-content: center;
}
.articles__small__list__more a {
    position: relative;
    width: fit-content;
    text-decoration: none;
    color: var(--sec200);
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
}
.articles__small__list__more a::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: var(--sec200);
    transition: var(--transitionTime);
}
.articles__small__list__more a:hover:before {
    width: 100%;
}
.articlesList__tags {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
}
.articlesList__tags a {
    padding: 10px 18px 12px;
    box-sizing: border-box;
    border: 1px solid var(--gr400);
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: black;
    margin: 0 10px 10px 0;
    transition: var(--transitionTime);
}
.articlesList__tags a:last-child {
    margin: 0 0 10px 0;
}
.articlesList__tags a:hover {
    background: var(--gr300);
}
.newsList__box__text__tag {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.newsList__box__text__tag__box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 10px 6px 6px;
    box-sizing: border-box;
    background: var(--gr200);
    border-radius: 4px;
}
.newsList__box__text__tag__box img {
    height: 16px;
    margin-right: 10px;
}
.newsList__box__text__tag__box p {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: black;
}
/*** NEWS LIST ***/

/* BREAD */
.bread {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
}
.breadTarget {
    display: flex;
    align-items: center;
}
.breadTarget:last-child svg {
    display: none;
}
.breadTarget a {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: var(--sec200);
    transition: var(--transitionTime);
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.breadTarget a:hover {
    color: var(--Black);
}
.breadTarget svg {
    margin: 0 10px;
}
/*** BREAD ***/

/* CURRENT NEWS */
.openNews__margin {
    margin-bottom: 14px;
}
.openNews {
    display: flex;
    flex-direction: column;
}
.openNews__titleImg {
    width: 100%;
}
.openNews__titleImg img {
    width: 100%;
    height: 100%;
}
.openNews__title h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: var(--Black);
}
.openNews__statistics {
    display: flex;
    flex-direction: column;
}
.openNews__statistics__dateEye {
    display: flex;
}
.openNews__statistics__dateEye .openNews__statistics__box:first-child {
    margin-right: 18px;
}
.openNews__statistics__box {
    display: flex;
    align-items: center;
}
.openNews__statistics__box svg {
    margin-right: 7.5px;
}
.openNews__statistics__box p {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: var(--sec200);
}
.openNews__textNews p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: var(--sec300);
}
.openNews__textNews img {
    width: 100%;
    margin: 30px 0;
    border-radius: 4px;
}
.openNews__tags {
    display: flex;
    margin-top: 16px;
}
.openNews__currentTag {
    padding: 6px 12px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: var(--sec300);
    background: var(--gr200);
    margin-right: 8px;
    border-radius: 60px;
}
.openNews__currentTag:last-child {
    margin-right: 0;
}
.newsReadMore {
    margin-top: 60px;
}
.newsReadMore h3 {
    margin-bottom: 30px;
}
/*** CURRENT NEWS ***/

/* NEWS ARTICLES SEARCH */
.articleSearch__issue {
    display: flex;
    flex-direction: column;
}
.articleSearch__answerBox {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    transition: var(--transitionTime);
}
.articleSearch__answerBox p {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: var(--Black);
    margin-bottom: 6px;
}
.articleSearch__answerBox span {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;
    color: var(--pri500);
    margin-bottom: 24px;
}
.articleSearch__answerBox hr {
    width: 100%;
    height: 1px;
    border: none;
    background: var(--sec50);
    transition: var(--transitionTime);
    border-bottom: 1px solid transparent;
}
p.articleSearch__notFound {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: var(--sec300);
    margin-bottom: 40px;
}
span.articleSearch__notFound {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: var(--sec300);
    margin-bottom: 14px;
}
.newsSearchButton__mobil,
.searchIconGrey {
    display: none;
}
/*** NEWS ARTICLES SEARCH ***/
